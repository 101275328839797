import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Separator } from '@/components/ui/separator'
import { EventHero } from '@/components/events/EventHero'
import { EventHost } from '@/components/events/EventHost'
import { EventAttendees } from '@/components/events/EventAttendees'
import { EventSidebar } from '@/components/events/EventSidebar'
import { RSVPConfirmationDialog } from '@/components/events/RSVPConfirmationDialog'
import { LiveEventDialog } from '@/components/events/LiveEventDialog'
import { EventDetailsSkeleton } from '@/components/events/EventDetailsSkeleton'
import { CommentSection } from '@/components/shared/CommentSection'
import { PageBreadcrumb } from '@/components/navigation/PageBreadcrumb'
import { getTimeUntilEvent } from '@/lib/date'
import sdk from '@/sdk'
import type { Event } from '@/types/event'

export function EventDetails() {
  const { event_slug } = useParams()
  const [event, setEvent] = useState<Event | null>(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showLiveEvent, setShowLiveEvent] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const loadEvent = async () => {
      if (event_slug) {
        try {
          const response = await sdk.events.getEventBySlug(event_slug)
          if (response.data) {
            setEvent(response.data)
          }
        } catch (err) {
          console.error('Failed to load event:', err)
          setError('Failed to load event. Please try again later.')
        } finally {
          setLoading(false)
        }
      }
    }

    loadEvent()
  }, [event_slug])

  if (loading) {
    return <EventDetailsSkeleton />
  }

  if (error) return <div className="text-red-500">{error}</div>
  if (!event) return <div>Event not found</div>

  const timeUntilEvent = getTimeUntilEvent(event.date, event.startTime)

  const handleRSVP = async () => {
    try {
      const response = await sdk.events.rsvpEvent(event.id, 'going')
      if (response.data) {
        setEvent(response.data)
        setShowConfirmation(true)
      }
    } catch (error) {
      console.error('Failed to RSVP:', error)
    }
  }

  const handleWatchLive = () => {
    setShowLiveEvent(true)
  }

  return (
    <div className="max-w-7xl mx-auto space-y-8">
      <PageBreadcrumb 
        items={[
          { title: event.title }
        ]} 
      />

      <EventHero event={event} timeUntilEvent={timeUntilEvent} />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Main Content */}
        <div className="md:col-span-2 space-y-8">
          <div className="space-y-6">
            <EventHost host={event.host} />
            <div className="prose max-w-none">
              <p className="whitespace-pre-line">{event.description}</p>
            </div>
          </div>

          <Separator />

          <EventAttendees 
            eventId={event.id}
            totalAttendees={event.stats.totalAttendees}
            initialAttendees={event.attendees}
          />

          <Separator />

          <CommentSection 
            comments={event.comments}
            totalComments={event.stats.totalComments}
          />
        </div>

        {/* Sidebar */}
        <div className="md:col-span-1">
          <EventSidebar 
            event={event}
            onRSVP={handleRSVP}
            onWatchLive={handleWatchLive}
          />
        </div>
      </div>

      {/* Dialogs */}
      <RSVPConfirmationDialog
        event={event}
        open={showConfirmation}
        onOpenChange={setShowConfirmation}
      />

      {event.isLive && (
        <LiveEventDialog
          event={event}
          open={showLiveEvent}
          onOpenChange={setShowLiveEvent}
        />
      )}
    </div>
  )
}