import { useState, useEffect } from 'react'
import { Users } from 'lucide-react'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { EventAttendeesDialog } from './EventAttendeesDialog'
import sdk from '@/sdk'
import type { Event } from '@/types/event'

interface EventAttendeesProps {
  eventId: string
  totalAttendees: number
  initialAttendees: Event['attendees']
}

export function EventAttendees({ eventId, totalAttendees, initialAttendees }: EventAttendeesProps) {
  const [attendees] = useState<Event['attendees']>(initialAttendees)
  const [showAllAttendees, setShowAllAttendees] = useState(false)

  const hasMoreAttendees = totalAttendees > attendees.length

  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Users className="h-5 w-5" />
            <h3 className="font-semibold">{totalAttendees} attendees</h3>
          </div>
          {hasMoreAttendees && (
            <Button variant="link" onClick={() => setShowAllAttendees(true)}>
              See all
            </Button>
          )}
        </div>
        <div className="flex flex-wrap gap-4">
          {attendees.map((attendee) => (
            <div key={attendee.id} className="flex items-center gap-2">
              <Avatar>
                <AvatarImage src={attendee.avatar} alt={attendee.name} />
              </Avatar>
              <span className="text-sm font-medium">{attendee.name}</span>
            </div>
          ))}
        </div>
      </div>

      <EventAttendeesDialog
        eventId={eventId}
        open={showAllAttendees}
        onOpenChange={setShowAllAttendees}
      />
    </>
  )
}