import { Calendar, Video, Clock, Users, Bookmark } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { format } from 'date-fns'
import { Event } from '@/api/events'

interface EventCardProps {
  event: Event
  onRSVP: (eventId: string) => void
  onSave: (eventId: string) => void
}

export function EventCard({ event, onRSVP, onSave }: EventCardProps) {
  const formattedDate = format(new Date(event.date), 'MMM d')

  const handleRSVP = (e: React.MouseEvent) => {
    e.preventDefault()
    onRSVP(event.id)
  }

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault()
    onSave(event.id)
  }

  return (
    <div className="rounded-lg border bg-card overflow-hidden">
      <div className="relative h-48">
        <img
          src={event.image}
          alt={event.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 left-4 bg-white rounded-lg p-2 text-center min-w-[48px]">
          <div className="text-sm font-medium">
            {formattedDate.split(' ')[1]}
          </div>
          <div className="text-xs uppercase">
            {formattedDate.split(' ')[0]}
          </div>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className={cn(
            "absolute top-4 right-4 bg-white/80 hover:bg-white",
            event.saved && "text-primary"
          )}
          onClick={handleSave}
        >
          <Bookmark className="h-4 w-4" />
        </Button>
      </div>
      <div className="p-6 space-y-4">
        <div className="space-y-2">
          <h3 className="font-semibold line-clamp-2">{event.title}</h3>
          <div className="flex items-center gap-2 text-sm text-muted-foreground">
            <Clock className="h-4 w-4" />
            <span>{event.startTime} - {event.endTime} {event.timezone}</span>
          </div>
          <div className="flex items-center gap-2 text-sm text-muted-foreground">
            <Users className="h-4 w-4" />
            <span>Host: {event.host.name}</span>
          </div>
          <div className="flex flex-wrap gap-2">
            <div className="flex items-center gap-1 text-xs bg-accent px-2 py-1 rounded-full">
              <Video className="h-3 w-3" />
              {event.type}
            </div>
          </div>
        </div>
        <Button
          className="w-full"
          variant={event.rsvped ? "secondary" : "default"}
          onClick={handleRSVP}
        >
          {event.rsvped ? 'Attending' : 'RSVP'}
        </Button>
      </div>
    </div>
  )
}