import { AppGroup } from '@/types/navigation'

// Mock API endpoint to fetch menu groups
export async function fetchMenuGroups(): Promise<AppGroup[]> {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 500))

  return [
    {
      id: 'compliance',
      name: 'Compliance & Segurança',
      slug: 'compliance-seguranca',
      spaces: [
        {
          id: 'compliance-posts',
          icon: 'MessageSquare',
          label: 'Posts',
          slug: 'posts',
          module: 'Posts'
        },
        {
          id: 'compliance-events',
          icon: 'Calendar',
          label: 'Events',
          slug: 'events',
          module: 'Events'
        },
        {
          id: 'compliance-channels',
          icon: 'MessagesSquare',
          label: 'Channels',
          slug: 'channels',
          module: 'Channels'
        },
        {
          id: 'compliance-members',
          icon: 'Users',
          label: 'Members',
          slug: 'members',
          module: 'Members'
        }
      ]
    },
    {
      id: 'futurismo',
      name: 'Futurismo',
      slug: 'futurismo',
      spaces: [
        {
          id: 'futurismo-posts',
          icon: 'MessageSquare',
          label: 'Posts',
          slug: 'posts',
          module: 'Posts'
        },
        {
          id: 'futurismo-events',
          icon: 'Calendar',
          label: 'Events',
          slug: 'events',
          module: 'Events'
        },
        {
          id: 'futurismo-courses',
          icon: 'GraduationCap',
          label: 'Courses',
          slug: 'courses',
          module: 'Courses'
        },
        {
          id: 'futurismo-channels',
          icon: 'MessagesSquare',
          label: 'Channels',
          slug: 'channels',
          module: 'Channels'
        },
        {
          id: 'futurismo-members',
          icon: 'Users',
          label: 'Members',
          slug: 'members',
          module: 'Members'
        }
      ]
    },
    {
      id: 'rh',
      name: 'Recursos Humanos',
      slug: 'recursos-humanos',
      spaces: [
        {
          id: 'rh-posts',
          icon: 'MessageSquare',
          label: 'Posts',
          slug: 'posts',
          module: 'Posts'
        },
        {
          id: 'rh-events',
          icon: 'Calendar',
          label: 'Events',
          slug: 'events',
          module: 'Events'
        },
        {
          id: 'rh-channels',
          icon: 'MessagesSquare',
          label: 'Channels',
          slug: 'channels',
          module: 'Channels'
        },
        {
          id: 'rh-members',
          icon: 'Users',
          label: 'Members',
          slug: 'members',
          module: 'Members'
        }
      ]
    }
  ]
}