import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Search, Hash, Users } from 'lucide-react'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'

// Mock data
const mockChannels = [
  {
    id: '1',
    name: 'general',
    description: 'General discussions about anything and everything',
    members: 128,
    joined: true,
  },
  {
    id: '2',
    name: 'help',
    description: 'Get help with your questions',
    members: 89,
    joined: false,
  },
  // Add more mock channels...
]

export function Channels() {
  const { group_slug, space_slug } = useParams()
  const [searchQuery, setSearchQuery] = useState('')
  const [channels, setChannels] = useState(mockChannels)

  const handleJoinChannel = (channelId: string) => {
    setChannels(channels.map(channel =>
      channel.id === channelId
        ? { ...channel, joined: !channel.joined }
        : channel
    ))
  }

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-3xl font-bold">Channels</h1>
        <p className="text-muted-foreground">
          Join channels and participate in discussions
        </p>
      </div>

      <div className="relative">
        <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search channels..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="pl-10"
        />
      </div>

      <div className="space-y-4">
        {channels.map((channel) => (
          <div
            key={channel.id}
            className="rounded-lg border bg-card p-6"
          >
            <div className="flex items-start justify-between gap-4">
              <div className="space-y-1">
                <div className="flex items-center gap-2">
                  <Hash className="h-4 w-4" />
                  <Link
                    to={`/g/${group_slug}/${space_slug}/${channel.id}`}
                    className="text-lg font-medium hover:underline"
                  >
                    {channel.name}
                  </Link>
                </div>
                <p className="text-sm text-muted-foreground">
                  {channel.description}
                </p>
                <div className="flex items-center gap-1 text-sm text-muted-foreground">
                  <Users className="h-4 w-4" />
                  <span>{channel.members} members</span>
                </div>
              </div>
              <Button
                variant={channel.joined ? "default" : "outline"}
                onClick={() => handleJoinChannel(channel.id)}
              >
                {channel.joined ? 'Joined' : 'Join Channel'}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}