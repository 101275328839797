import type { SDKConfig, SDKResponse } from '../types'
import { mockApi } from '../mock'

export class BaseModule {
  constructor(protected config: SDKConfig) {}

  protected async get<T>(path: string, options: RequestInit = {}): Promise<SDKResponse<T>> {
    return this.request<T>('GET', path, options)
  }

  protected async post<T>(path: string, data?: unknown, options: RequestInit = {}): Promise<SDKResponse<T>> {
    return this.request<T>('POST', path, {
      ...options,
      body: JSON.stringify(data)
    })
  }

  protected async patch<T>(path: string, data?: unknown, options: RequestInit = {}): Promise<SDKResponse<T>> {
    return this.request<T>('PATCH', path, {
      ...options,
      body: JSON.stringify(data)
    })
  }

  protected async delete<T>(path: string, options: RequestInit = {}): Promise<SDKResponse<T>> {
    return this.request<T>('DELETE', path, options)
  }

  private async request<T>(
    method: string,
    path: string,
    options: RequestInit = {}
  ): Promise<SDKResponse<T>> {
    // Use mocks in development if configured
    if (this.config.useMocks && process.env.NODE_ENV === 'development') {
      const [module, ...rest] = path.split('/').filter(Boolean)
      const mockModule = mockApi[module as keyof typeof mockApi]
      if (mockModule) {
        const mockMethod = rest[rest.length - 1] || method.toLowerCase()
        if (mockModule[mockMethod]) {
          return mockModule[mockMethod]()
        }
      }
    }

    const url = `${this.config.baseUrl || ''}${path}`
    
    const headers = {
      'Content-Type': 'application/json',
      ...(this.config.apiKey && { 'Authorization': `Bearer ${this.config.apiKey}` }),
      ...options.headers
    }

    const response = await fetch(url, {
      ...options,
      method,
      headers
    })

    const data = await response.json()

    return {
      data,
      status: response.status
    }
  }
}