import DOMPurify from 'dompurify'
import { useEffect, useRef } from 'react'

interface SafeHTMLProps {
  content: string
  className?: string
}

export function SafeHTML({ content, className }: SafeHTMLProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      // Configure DOMPurify
      const purifyConfig = {
        ALLOWED_TAGS: [
          'p', 'br', 'b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li',
          'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'code',
          'pre', 'hr', 'span'
        ],
        ALLOWED_ATTR: ['href', 'target', 'rel', 'class'],
        ALLOW_DATA_ATTR: false,
        ADD_ATTR: [['target', '_blank'], ['rel', 'noopener noreferrer']],
        USE_PROFILES: { html: true }
      }

      // Sanitize and set content
      const cleanHTML = DOMPurify.sanitize(content, purifyConfig)
      containerRef.current.innerHTML = cleanHTML
    }
  }, [content])

  return (
    <div 
      ref={containerRef} 
      className={className}
    />
  )
}