import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Plus } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { CreateEventDialog } from '@/components/events/CreateEventDialog'
import { EventCard } from '@/components/events/EventCard'
import { FeaturedEvent } from '@/components/events/FeaturedEvent'
import { EventsSkeletonList } from '@/components/events/EventSkeleton'
import { PageBreadcrumb } from '@/components/navigation/PageBreadcrumb'
import sdk from '@/sdk'
import type { Event } from '@/types/event'

export function Events() {
  const [showCreateEvent, setShowCreateEvent] = useState(false)
  const [activeTab, setActiveTab] = useState('all')
  const [events, setEvents] = useState<Event[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const { group_slug, space_slug } = useParams()

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const response = await sdk.events.getEvents()
        if (response.data) {
          setEvents(response.data.items)
        }
      } catch (err) {
        console.error('Failed to load events:', err)
        setError('Failed to load events. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    loadEvents()
  }, [])

  const handleRSVP = async (eventId: string) => {
    try {
      const response = await sdk.events.rsvpEvent(eventId, 'going')
      if (response.data) {
        setEvents(events.map(e => e.id === eventId ? response.data : e))
      }
    } catch (error) {
      console.error('Failed to RSVP:', error)
    }
  }

  const handleSave = async (eventId: string) => {
    const event = events.find(e => e.id === eventId)
    if (event) {
      try {
        const response = await sdk.events.updateEvent(eventId, { saved: !event.saved })
        if (response.data) {
          setEvents(events.map(e => e.id === eventId ? response.data : e))
        }
      } catch (error) {
        console.error('Failed to save event:', error)
      }
    }
  }

  if (error) return <div className="text-red-500">{error}</div>
  if (!group_slug || !space_slug) return null

  const featuredEvent = events.find(event => event.isFeatured)
  const filteredEvents = events.filter(event => !event.isFeatured)

  if (loading) {
    return <EventsSkeletonList />
  }

  return (
    <div className="space-y-8">
      <PageBreadcrumb />

      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-bold">Event Calendar</h1>
          <p className="text-muted-foreground">
            Browse and manage upcoming events
          </p>
        </div>
        <Button onClick={() => setShowCreateEvent(true)}>
          <Plus className="mr-2 h-4 w-4" />
          New Event
        </Button>
      </div>

      {/* Tabs */}
      <Tabs defaultValue="all" className="w-full" onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="all">All events</TabsTrigger>
          <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
          <TabsTrigger value="past">Past</TabsTrigger>
        </TabsList>
      </Tabs>

      {/* Featured Event */}
      {featuredEvent && activeTab === 'all' && (
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Featured event</h2>
          <Link to={`/g/${group_slug}/${space_slug}/${featuredEvent.slug}`}>
            <FeaturedEvent
              event={featuredEvent}
              onRSVP={handleRSVP}
              onSave={handleSave}
            />
          </Link>
        </div>
      )}

      {/* Event List */}
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">June 2024</h2>
        <div className="grid gap-4 md:grid-cols-2">
          {filteredEvents.map((event) => (
            <Link 
              key={event.id}
              to={`/g/${group_slug}/${space_slug}/${event.slug}`}
            >
              <EventCard
                event={event}
                onRSVP={handleRSVP}
                onSave={handleSave}
              />
            </Link>
          ))}
        </div>
      </div>

      {/* Create Event Dialog */}
      <CreateEventDialog
        open={showCreateEvent}
        onOpenChange={setShowCreateEvent}
      />
    </div>
  )
}