import { Avatar, AvatarImage } from '@/components/ui/avatar'
import type { Event } from '@/types/event'

interface EventHostProps {
  host: Event['host']
}

export function EventHost({ host }: EventHostProps) {
  return (
    <div className="flex items-center gap-4">
      <Avatar className="h-12 w-12">
        <AvatarImage src={host.avatar} alt={host.name} />
      </Avatar>
      <div>
        <h3 className="font-semibold">Hosted by {host.name}</h3>
        <p className="text-sm text-muted-foreground">{host.role}</p>
      </div>
    </div>
  )
}