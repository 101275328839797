import { Routes, Route, Navigate } from 'react-router-dom';
import { PublicLayout } from '@/components/layouts/PublicLayout';
import { AuthenticatedLayout } from '@/components/layouts/AuthenticatedLayout';
import { Home } from '@/pages/public/Home';
import { Login } from '@/pages/public/Login';
import { Signup } from '@/pages/public/Signup';
import { About } from '@/pages/public/About';
import { Pricing } from '@/pages/public/Pricing';
import { Dashboard } from '@/pages/private/Dashboard';
import { Settings } from '@/pages/private/Settings';
import { Profile } from '@/pages/private/Profile';
import { Members } from '@/pages/private/Members';
import { Posts } from '@/pages/private/Posts';
import { Events } from '@/pages/private/Events';
import { Channels } from '@/pages/private/Channels';
import { ChannelDetails } from '@/pages/private/ChannelDetails';
import { SpaceRouter } from './SpaceRouter';

export function AppRoutes() {
  // For demo purposes, we'll skip auth check
  const isAuthenticated = true;

  return (
    <Routes>
      {/* Public routes */}
      <Route element={<PublicLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
      </Route>

      {/* Protected routes */}
      <Route
        element={
          isAuthenticated ? (
            <AuthenticatedLayout />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/profile" element={<Profile />} />
        
        {/* Group and Space routes */}
        <Route path="/g/:group_slug/:space_slug/*" element={<SpaceRouter />} />
      </Route>
    </Routes>
  );
}