import { useLiveDate } from '@/hooks/useLiveDate'

interface LiveDateProps {
  date: string | Date
  className?: string
}

export function LiveDate({ date, className }: LiveDateProps) {
  const formattedDate = useLiveDate(date)
  
  return (
    <span className={className}>
      {formattedDate}
    </span>
  )
}