import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Hash, Send, ArrowLeft } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Avatar, AvatarImage } from "@/components/ui/avatar"
import { LikeButton } from "@/components/shared/LikeButton"
import { LikedBy } from "@/components/shared/LikedBy"
import { cn } from "@/lib/utils"

// Mock data
const mockThreads = [
  {
    id: '1',
    user: {
      name: 'John Doe',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=John',
    },
    content: 'Hey everyone! Just joined the channel. Looking forward to collaborating with all of you!',
    createdAt: '2h ago',
    reactions: {
      '👍': 3,
      '❤️': 2
    },
    likedBy: [
      { id: '1', name: 'Alice', avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Alice' },
      { id: '2', name: 'Bob', avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Bob' },
    ],
    replies: [
      {
        id: '1',
        user: {
          name: 'Alice Smith',
          avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Alice',
        },
        content: 'Welcome to the channel! 👋',
        createdAt: '1h ago',
        reactions: {
          '👋': 2
        },
        likedBy: [
          { id: '3', name: 'Charlie', avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Charlie' },
        ],
      },
    ],
  },
  {
    id: '2',
    user: {
      name: 'Jane Smith',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Jane',
    },
    content: 'Just pushed a new update to the project. Check it out and let me know your thoughts!',
    createdAt: '3h ago',
    reactions: {
      '👍': 5,
      '🎉': 3
    },
    likedBy: [
      { id: '4', name: 'David', avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=David' },
      { id: '5', name: 'Eva', avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Eva' },
      { id: '6', name: 'Frank', avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Frank' },
    ],
    replies: [
      {
        id: '2',
        user: {
          name: 'David Wilson',
          avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=David',
        },
        content: 'Great work! The new features look amazing.',
        createdAt: '2h ago',
        reactions: {
          '👍': 3
        },
        likedBy: [
          { id: '7', name: 'Grace', avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Grace' },
          { id: '8', name: 'Henry', avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Henry' },
        ],
      },
    ],
  },
]

export function ChannelDetails() {
  const { id } = useParams()
  const [selectedThread, setSelectedThread] = useState<string | null>(null)
  const [newThreadMessage, setNewThreadMessage] = useState('')
  const [newReplyMessage, setNewReplyMessage] = useState('')
  const [threads] = useState(mockThreads)

  const selectedThreadData = threads.find(t => t.id === selectedThread)

  const handleNewThread = (e: React.FormEvent) => {
    e.preventDefault()
    if (newThreadMessage.trim()) {
      console.log('New thread:', newThreadMessage)
      setNewThreadMessage('')
    }
  }

  const handleNewReply = (e: React.FormEvent) => {
    e.preventDefault()
    if (newReplyMessage.trim()) {
      console.log('New reply:', newReplyMessage)
      setNewReplyMessage('')
    }
  }

  return (
    <div className="h-[calc(100vh-4rem)] flex gap-4">
      {/* Main Thread List */}
      <div className={cn(
        "flex-1 flex flex-col rounded-lg border bg-card min-w-[320px]",
        selectedThread && "hidden md:flex"
      )}>
        <div className="p-4 border-b">
          <div className="flex items-center gap-2">
            <Hash className="h-5 w-5" />
            <h2 className="text-lg font-semibold">general</h2>
          </div>
        </div>

        <ScrollArea className="flex-1 p-4">
          <div className="space-y-4">
            {threads.map((thread) => (
              <div
                key={thread.id}
                className={cn(
                  "p-4 rounded-lg hover:bg-accent/50 cursor-pointer",
                  selectedThread === thread.id && "bg-accent/50"
                )}
                onClick={() => setSelectedThread(thread.id)}
              >
                <div className="flex items-start gap-3">
                  <Avatar>
                    <AvatarImage src={thread.user.avatar} alt={thread.user.name} />
                  </Avatar>
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <span className="font-medium">{thread.user.name}</span>
                      <span className="text-sm text-muted-foreground">
                        {thread.createdAt}
                      </span>
                    </div>
                    <p className="mt-1">{thread.content}</p>
                    <div className="mt-2 space-y-2">
                      <LikedBy users={thread.likedBy} />
                      <div className="flex items-center gap-4">
                        <LikeButton initialReactions={thread.reactions} size="sm" />
                        <span className="text-sm text-muted-foreground">
                          {thread.replies.length} replies
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>

        <form onSubmit={handleNewThread} className="p-4 border-t">
          <div className="flex gap-2">
            <Input
              value={newThreadMessage}
              onChange={(e) => setNewThreadMessage(e.target.value)}
              placeholder="Start a new thread..."
            />
            <Button type="submit" size="icon">
              <Send className="h-4 w-4" />
            </Button>
          </div>
        </form>
      </div>

      {/* Thread Details */}
      {selectedThread && (
        <div 
          className={cn(
            "w-[384px] flex flex-col rounded-lg border bg-card",
            "fixed md:relative inset-0 md:inset-auto z-50 md:z-0",
            "w-full md:w-[384px]"
          )}
        >
          <div className="p-4 border-b">
            <div className="flex items-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                className="md:hidden"
                onClick={() => setSelectedThread(null)}
              >
                <ArrowLeft className="h-4 w-4" />
              </Button>
              <h3 className="font-semibold">Thread</h3>
            </div>
          </div>

          <ScrollArea className="flex-1 p-4">
            <div className="space-y-4">
              {/* Original Thread */}
              <div className="p-4 rounded-lg bg-accent/50">
                <div className="flex items-start gap-3">
                  <Avatar>
                    <AvatarImage
                      src={selectedThreadData?.user.avatar}
                      alt={selectedThreadData?.user.name}
                    />
                  </Avatar>
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium">
                        {selectedThreadData?.user.name}
                      </span>
                      <span className="text-sm text-muted-foreground">
                        {selectedThreadData?.createdAt}
                      </span>
                    </div>
                    <p className="mt-1">{selectedThreadData?.content}</p>
                    <div className="mt-2 space-y-2">
                      <LikedBy users={selectedThreadData?.likedBy || []} />
                      <LikeButton 
                        initialReactions={selectedThreadData?.reactions || {}} 
                        size="sm"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Separator />

              {/* Replies */}
              {selectedThreadData?.replies.map((reply) => (
                <div key={reply.id} className="p-4">
                  <div className="flex items-start gap-3">
                    <Avatar>
                      <AvatarImage src={reply.user.avatar} alt={reply.user.name} />
                    </Avatar>
                    <div className="flex-1">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">{reply.user.name}</span>
                        <span className="text-sm text-muted-foreground">
                          {reply.createdAt}
                        </span>
                      </div>
                      <p className="mt-1">{reply.content}</p>
                      <div className="mt-2 space-y-2">
                        <LikedBy users={reply.likedBy} />
                        <LikeButton 
                          initialReactions={reply.reactions} 
                          size="sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ScrollArea>

          <form onSubmit={handleNewReply} className="p-4 border-t">
            <div className="flex gap-2">
              <Input
                value={newReplyMessage}
                onChange={(e) => setNewReplyMessage(e.target.value)}
                placeholder="Reply to thread..."
              />
              <Button type="submit" size="icon">
                <Send className="h-4 w-4" />
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}