import type { Plan, PricingFeature } from '@/types/pricing'
import type { SDKResponse } from '../types'
import { BaseModule } from './base'

export class PricingModule extends BaseModule {
  /**
   * Get all available pricing plans
   */
  async getPlans(): Promise<SDKResponse<Plan[]>> {
    return this.get('/pricing/plans')
  }

  /**
   * Get detailed pricing features comparison
   */
  async getFeatures(): Promise<SDKResponse<PricingFeature[]>> {
    return this.get('/pricing/features')
  }

  /**
   * Get a specific plan by ID
   */
  async getPlan(planId: string): Promise<SDKResponse<Plan>> {
    return this.get(`/pricing/plans/${planId}`)
  }
}