import { formatDistanceToNow, format, isToday, isYesterday, isPast, parseISO } from 'date-fns'

export function formatDate(date: string | Date): string {
  const parsedDate = typeof date === 'string' ? new Date(date) : date

  // For invalid dates
  if (isNaN(parsedDate.getTime())) {
    return 'Invalid date'
  }

  // For recent dates, show relative time
  const distanceInWords = formatDistanceToNow(parsedDate, { addSuffix: true })
  
  // If it's within the last 24 hours, show relative time
  if (distanceInWords.includes('less than a minute')) {
    return 'just now'
  }
  if (distanceInWords.includes('minute') || distanceInWords.includes('hour')) {
    return distanceInWords
  }

  // If it's today, show "Today at HH:mm"
  if (isToday(parsedDate)) {
    return `Today at ${format(parsedDate, 'HH:mm')}`
  }

  // If it's yesterday, show "Yesterday at HH:mm"
  if (isYesterday(parsedDate)) {
    return `Yesterday at ${format(parsedDate, 'HH:mm')}`
  }

  // For older dates, show full date and time
  return format(parsedDate, 'MMM d, yyyy HH:mm')
}

export function getTimeUntilEvent(date: string, startTime: string): string {
  const eventDateTime = parseISO(`${date}T${convertTo24Hour(startTime)}`)
  
  if (isPast(eventDateTime)) {
    return 'Event has ended'
  }
  
  return `Starts ${formatDistanceToNow(eventDateTime, { addSuffix: true })}`
}

function convertTo24Hour(time12h: string): string {
  const [time, modifier] = time12h.split(' ')
  let [hours, minutes] = time.split(':')
  
  if (hours === '12') {
    hours = '00'
  }
  
  if (modifier === 'PM') {
    hours = String(parseInt(hours, 10) + 12)
  }
  
  return `${hours.padStart(2, '0')}:${minutes}:00`
}