import type { AppGroup } from '@/types/navigation'
import type { SDKResponse } from '../types'
import { BaseModule } from './base'

export class NavigationModule extends BaseModule {
  /**
   * Get menu groups
   */
  async getMenuGroups(): Promise<SDKResponse<AppGroup[]>> {
    return this.get('/navigation/menu')
  }

  /**
   * Get a specific group by slug
   */
  async getGroupBySlug(slug: string): Promise<SDKResponse<AppGroup>> {
    return this.get(`/navigation/groups/${slug}`)
  }
}