import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PostBanner } from '@/components/posts/PostBanner'
import { PostCard } from '@/components/posts/PostCard'
import { PostSkeletonList } from '@/components/posts/PostSkeleton'
import { PageBreadcrumb } from '@/components/navigation/PageBreadcrumb'
import { CreatePostDialog } from '@/components/posts/CreatePostDialog'
import { useAuthContext } from '@/providers/AuthProvider'
import { useToast } from '@/components/ui/use-toast'
import { supabase } from '@/config/supabase'
import type { Post } from '@/types/post'
import type { AppSpace } from '@/types/navigation'
import type { RealtimeChannel } from '@supabase/supabase-js'

export function Posts() {
  const { session } = useAuthContext()
  const { group_slug, space_slug } = useParams()
  const { toast } = useToast()
  const [posts, setPosts] = useState<Post[]>([])
  const [loading, setLoading] = useState(true)
  const [spaceId, setSpaceId] = useState<string | null>(null)
  const [space, setSpace] = useState<AppSpace | null>(null)
  const [channel, setChannel] = useState<RealtimeChannel | null>(null)
  const [showCreatePost, setShowCreatePost] = useState(false)

  // First, get the space ID based on slugs
  useEffect(() => {
    const getSpaceId = async () => {
      if (!group_slug || !space_slug) {
        console.log('Missing group_slug or space_slug:', { group_slug, space_slug })
        return
      }

      console.log('Fetching space ID for:', { group_slug, space_slug })

      try {
        const { data, error } = await supabase
          .from('spaces')
          .select(`
            id, name, description, 
            groups!inner (
              id,
              slug
            )
          `)
          .eq('groups.slug', group_slug)
          .eq('slug', space_slug)
          .single()

        if (error) {
          console.error('Space query error:', error)
          throw error
        }

        if (data) {
          console.log('Found space:', data)
          setSpace(data)
          setSpaceId(data.id)
        } else {
          console.log('No space found')
        }
      } catch (error) {
        console.error('Failed to get space ID:', error)
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load space information"
        })
      }
    }

    getSpaceId()
  }, [group_slug, space_slug, toast])

  // Then, load posts using the space ID
  useEffect(() => {
    const loadPosts = async () => {
      if (!session?.community.id || !spaceId) {
        console.log('Missing required data:', { 
          communityId: session?.community.id, 
          spaceId 
        })
        return
      }

      console.log('Loading posts for:', { 
        communityId: session.community.id, 
        spaceId 
      })

      try {
        const { data, error } = await supabase
          .from('posts')
          .select(`
            id,
            slug,
            title,
            content,
            image_url,
            created_at,
            updated_at,
            visibility,
            status,
            featured,
            author:community_profiles!posts_author_id_fkey (
              id,
              display_name,
              avatar_url,
              role
            ),
            space:spaces!inner (
              id,
              name,
              slug,
              groups!inner (
                id,
                name,
                slug
              )
            ),
            reactions:post_reactions (
              emoji,
              profile:community_profiles!post_reactions_profile_id_fkey (
                id,
                display_name,
                avatar_url
              )
            ),
            comments:post_comments (
              id,
              content,
              created_at,
              author:community_profiles!post_comments_author_id_fkey (
                display_name,
                avatar_url
              )
            )
          `)
          .eq('community_id', session.community.id)
          .eq('space_id', spaceId)
          .order('created_at', { ascending: false })

        if (error) {
          console.error('Posts query error:', error)
          throw error
        }

        console.log('Found posts:', data)
        setPosts(data)
      } catch (error) {
        console.error('Failed to load posts:', error)
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load posts. Please try again later."
        })
      } finally {
        setLoading(false)
      }
    }

    loadPosts()
  }, [session?.community.id, spaceId, toast])

  // Setup Realtime subscription for posts
  useEffect(() => {
    if (!spaceId) {
      console.log('No spaceId for realtime subscription')
      return
    }

    console.log('Setting up realtime subscription for space:', spaceId)

    const channel = supabase
      .channel(`posts:${spaceId}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'posts',
          filter: `space_id=eq.${spaceId}`
        },
        async (payload) => {
          console.log('Received realtime update:', payload)
          console.log('Event type:', payload.eventType)
          console.log('Current posts state:', posts)

          // Handle post deletion
          if (payload.eventType === 'DELETE') {
            console.log('Post deleted:', payload.old)
            console.log('Post ID to remove:', payload.old.id)
            setPosts(currentPosts => {
              console.log('Filtering posts, current length:', currentPosts.length)
              const newPosts = currentPosts.filter(post => post.id !== payload.old.id)
              console.log('New posts length after filter:', newPosts.length)
              return newPosts
            })
            return
          }

          // Handle post updates and insertions
          if (!session?.community.id || !spaceId) return
          
          const { data, error } = await supabase
            .from('posts')
            .select(`
              id,
              slug,
              title,
              content,
              image_url,
              created_at,
              updated_at,
              visibility,
              status,
              featured,
              author:community_profiles!posts_author_id_fkey (
                id,
                display_name,
                avatar_url,
                role
              ),
              space:spaces!inner (
                id,
                name,
                slug,
                groups!inner (
                  id,
                  name,
                  slug
                )
              ),
              reactions:post_reactions (
                emoji,
                profile:community_profiles!post_reactions_profile_id_fkey (
                  id,
                  display_name,
                  avatar_url
                )
              ),
              comments:post_comments (
                id,
                content,
                created_at,
                author:community_profiles!post_comments_author_id_fkey (
                  display_name,
                  avatar_url
                )
              )
            `)
            .eq('community_id', session.community.id)
            .eq('space_id', spaceId)
            .order('created_at', { ascending: false })

          if (error) {
            console.error('Error reloading posts:', error)
            return
          }

          console.log('Posts reloaded after update:', data)
          setPosts(data)
        }
      )
      .subscribe(status => {
        console.log('Subscription status:', status)
      })

    setChannel(channel)

    return () => {
      console.log('Cleaning up realtime subscription')
      channel.unsubscribe()
    }
  }, [spaceId, session?.community.id])

  if (!group_slug || !space_slug) return null

  if (loading) {
    return (
      <div className="space-y-8">
        <PageBreadcrumb />
        <PostBanner />
        <PostSkeletonList />
      </div>
    )
  }

  return (
    <div className="space-y-8">
      <PageBreadcrumb />
      <PostBanner space={space} onCreatePost={() => setShowCreatePost(true)} />
      
      <div className="space-y-6">
        {posts.length === 0 ? (
          <div className="text-center py-8 text-muted-foreground">
            Nenhum post publicado até o momento.
          </div>
        ) : (
          posts.map((post) => (
            <PostCard key={post.id} post={post} />
          ))
        )}
      </div>

      <CreatePostDialog
        open={showCreatePost}
        onOpenChange={setShowCreatePost}
        groupSlug={group_slug}
        spaceSlug={space_slug}
      />
    </div>
  )
}