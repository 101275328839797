import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Separator } from '@/components/ui/separator'
import { CourseHero } from '@/components/courses/CourseHero'
import { CoursePurchase } from '@/components/courses/CoursePurchase'
import { CourseContent } from '@/components/courses/CourseContent'
import { CourseMainContent } from '@/components/courses/CourseMainContent'
import { CourseDetailsSkeleton } from '@/components/courses/CourseDetailsSkeleton'
import { PageBreadcrumb } from '@/components/navigation/PageBreadcrumb'
import sdk from '@/sdk'
import type { Course } from '@/types/course'

export function CourseDetails() {
  const { course_slug } = useParams()
  const [course, setCourse] = useState<Course | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const loadCourse = async () => {
      if (course_slug) {
        try {
          const response = await sdk.courses.getCourseBySlug(course_slug)
          if (response.data) {
            setCourse(response.data)
          }
        } catch (err) {
          console.error('Failed to load course:', err)
          setError('Failed to load course. Please try again later.')
        } finally {
          setLoading(false)
        }
      }
    }

    loadCourse()
  }, [course_slug])

  if (loading) {
    return <CourseDetailsSkeleton />
  }

  if (error) return <div className="text-red-500">{error}</div>
  if (!course) return <div>Course not found</div>

  const totalLectures = course.modules.reduce(
    (total, section) => total + section.lectures.length,
    0
  )

  return (
    <div className="max-w-7xl mx-auto space-y-8">
      <PageBreadcrumb 
        items={[
          { title: course.title }
        ]} 
      />

      {/* Grid Layout */}
      <div className="grid lg:grid-cols-12 gap-8">
        {/* Main Column */}
        <div className="lg:col-span-8">
          <CourseHero course={course} totalLectures={totalLectures} />
          
          {/* Mobile Purchase Section */}
          <div className="lg:hidden mt-8">
            <CoursePurchase course={course} />
          </div>

          <div className="mt-8">
            <CourseMainContent course={course} />
            <Separator className="my-8" />
            <CourseContent 
              modules={course.modules}
              totalLectures={totalLectures}
              totalHours={course.duration.total}
            />
          </div>
        </div>

        {/* Sidebar Column */}
        <div className="hidden lg:block lg:col-span-4">
          <div className="sticky top-8">
            <CoursePurchase course={course} />
          </div>
        </div>
      </div>
    </div>
  )
}