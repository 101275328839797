import { createContext, useContext, useEffect, useState } from 'react'
import { CommunitySettings, Theme } from '@/types/community'
import { fetchCommunitySettings, updateCommunitySettings } from '@/api/mock'

interface ThemeContextType {
  settings: CommunitySettings | null
  isLoading: boolean
  updateTheme: (settings: Partial<CommunitySettings>) => Promise<void>
}

const ThemeContext = createContext<ThemeContextType>({
  settings: null,
  isLoading: true,
  updateTheme: async () => {}
})

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [settings, setSettings] = useState<CommunitySettings | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const applyTheme = (theme: Theme) => {
    const root = document.documentElement
    
    // Extract HSL values from each color
    const extractHSL = (color: string) => {
      const match = color.match(/hsl\(([^)]+)\)/)
      return match ? match[1] : color
    }

    // Apply theme colors
    Object.entries(theme.colors).forEach(([key, value]) => {
      const hslValue = extractHSL(value)
      root.style.setProperty(`--${key}`, hslValue)
    })

    // Set radius
    root.style.setProperty('--radius', '0.5rem')
  }

  useEffect(() => {
    async function loadSettings() {
      try {
        const data = await fetchCommunitySettings()
        setSettings(data)
        applyTheme(data.theme)
      } catch (error) {
        console.error('Failed to load community settings:', error)
      } finally {
        setIsLoading(false)
      }
    }

    loadSettings()
  }, [])

  const updateTheme = async (newSettings: Partial<CommunitySettings>) => {
    if (!settings) return

    const updatedSettings = await updateCommunitySettings({
      ...settings,
      ...newSettings
    })

    if (newSettings.theme) {
      applyTheme(newSettings.theme)
    }

    setSettings(updatedSettings)
  }

  return (
    <ThemeContext.Provider value={{ settings, isLoading, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export function useCommunityTheme() {
  return useContext(ThemeContext)
}