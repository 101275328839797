import { Skeleton } from "@/components/ui/skeleton"

export function CourseDetailsSkeleton() {
  return (
    <div className="max-w-7xl mx-auto space-y-8">
      {/* Breadcrumb */}
      <div className="flex items-center gap-2">
        <Skeleton className="h-5 w-24" />
        <Skeleton className="h-4 w-4" />
        <Skeleton className="h-5 w-32" />
      </div>

      {/* Grid Layout */}
      <div className="grid lg:grid-cols-12 gap-8">
        {/* Main Column */}
        <div className="lg:col-span-8">
          {/* Course Hero */}
          <div className="space-y-6">
            <div className="flex flex-wrap gap-2">
              <Skeleton className="h-6 w-24" />
              <Skeleton className="h-6 w-32" />
            </div>
            <Skeleton className="h-8 w-3/4" />
            <Skeleton className="h-4 w-full" />
            <div className="flex flex-wrap gap-4">
              {Array.from({ length: 4 }).map((_, i) => (
                <div key={i} className="flex items-center gap-1">
                  <Skeleton className="h-4 w-4" />
                  <Skeleton className="h-4 w-24" />
                </div>
              ))}
            </div>
            <div className="flex items-center gap-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div>
                <Skeleton className="h-5 w-32" />
                <Skeleton className="h-4 w-48" />
              </div>
            </div>
          </div>

          {/* Mobile Purchase Section */}
          <div className="lg:hidden mt-8">
            <div className="rounded-lg border p-6 space-y-6">
              <Skeleton className="h-8 w-32" />
              <Skeleton className="h-10 w-full" />
            </div>
          </div>

          {/* Course Content */}
          <div className="mt-8 space-y-8">
            {/* Learning Objectives */}
            <div className="space-y-4">
              <Skeleton className="h-6 w-48" />
              <div className="grid md:grid-cols-2 gap-4">
                {Array.from({ length: 6 }).map((_, i) => (
                  <div key={i} className="flex gap-2">
                    <Skeleton className="h-5 w-5 shrink-0" />
                    <Skeleton className="h-5 w-full" />
                  </div>
                ))}
              </div>
            </div>

            {/* Course Modules */}
            <div className="space-y-4">
              <Skeleton className="h-6 w-48" />
              <div className="space-y-4">
                {Array.from({ length: 3 }).map((_, i) => (
                  <div key={i} className="border rounded-lg">
                    <div className="p-4 bg-muted/50">
                      <Skeleton className="h-5 w-64" />
                      <Skeleton className="h-4 w-32 mt-1" />
                    </div>
                    <div className="divide-y">
                      {Array.from({ length: 3 }).map((_, j) => (
                        <div key={j} className="p-4 flex items-center justify-between">
                          <div className="flex items-center gap-3">
                            <Skeleton className="h-4 w-4" />
                            <div>
                              <Skeleton className="h-4 w-48" />
                              <Skeleton className="h-3 w-24 mt-1" />
                            </div>
                          </div>
                          <Skeleton className="h-8 w-16" />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Sidebar Column */}
        <div className="hidden lg:block lg:col-span-4">
          <div className="sticky top-8">
            <div className="rounded-lg border overflow-hidden">
              <Skeleton className="w-full aspect-video" />
              <div className="p-6 space-y-6">
                <div className="space-y-2">
                  <Skeleton className="h-8 w-32" />
                  <Skeleton className="h-5 w-24" />
                </div>
                <Skeleton className="h-10 w-full" />
                <Skeleton className="h-10 w-full" />
                <div className="space-y-4 pt-4 border-t">
                  <Skeleton className="h-5 w-48" />
                  <div className="space-y-3">
                    {Array.from({ length: 4 }).map((_, i) => (
                      <div key={i} className="flex items-center gap-2">
                        <Skeleton className="h-4 w-4" />
                        <Skeleton className="h-4 w-full" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}