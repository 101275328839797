import { useState, useEffect } from 'react'
import { MoreHorizontal, MessageCircle, Pencil, Trash2 } from 'lucide-react'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger 
} from '@/components/ui/dropdown-menu'
import { LikeButton } from '@/components/shared/LikeButton'
import { LikedBy } from '@/components/shared/LikedBy'
import { CommentSection } from '@/components/shared/CommentSection'
import { LiveDate } from '@/components/shared/LiveDate'
import { SafeHTML } from '@/components/shared/SafeHTML'
import { useAuthContext } from '@/providers/AuthProvider'
import { useToast } from '@/components/ui/use-toast'
import { supabase } from '@/config/supabase'
import type { Post } from '@/types/post'
import type { RealtimeChannel } from '@supabase/supabase-js'

interface PostCardProps {
  post: Post
}

export function PostCard({ post }: PostCardProps) {
  const { session } = useAuthContext()
  const { toast } = useToast()
  const [showComments, setShowComments] = useState(false)
  const [currentPost, setCurrentPost] = useState(post)
  const [channel, setChannel] = useState<RealtimeChannel | null>(null)
  const [currentReactions, setCurrentReactions] = useState<Record<string, number>>({})

  // Check if user can edit/delete post
  const canModifyPost = session?.currentProfile.role === 'admin' || 
                       session?.currentProfile.role === 'moderator' ||
                       session?.currentProfile.id === post.author.id

  // Setup realtime subscriptions for reactions and comments
  useEffect(() => {
    console.log(`Setting up realtime monitoring for post ${post.id}`)

    const channel = supabase
      .channel(`post:${post.id}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'post_reactions',
          filter: `post_id=eq.${post.id}`
        },
        async (payload) => {
          console.log('Reaction change detected:', {
            event: payload.eventType,
            old: payload.old,
            new: payload.new
          })

          // Reload post reactions
          const { data: reactions } = await supabase
            .from('post_reactions')
            .select(`
              emoji,
              profile:community_profiles!post_reactions_profile_id_fkey (
                id,
                display_name,
                avatar_url
              )
            `)
            .eq('post_id', post.id)

          if (reactions) {
            console.log('Updated reactions:', reactions)
            
            // Update current post reactions
            setCurrentPost(prev => ({
              ...prev,
              reactions
            }))

            // Update reaction counts for LikeButton
            const counts = reactions.reduce((acc, r) => ({
              ...acc,
              [r.emoji]: (acc[r.emoji] || 0) + 1
            }), {} as Record<string, number>)
            
            setCurrentReactions(counts)
          }
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'post_comments',
          filter: `post_id=eq.${post.id}`
        },
        async (payload) => {
          console.log('Comment change detected:', {
            event: payload.eventType,
            old: payload.old,
            new: payload.new
          })

          // Reload post comments
          const { data: comments } = await supabase
            .from('post_comments')
            .select(`
              id,
              content,
              created_at,
              author:community_profiles!post_comments_author_id_fkey (
                display_name,
                avatar_url
              )
            `)
            .eq('post_id', post.id)
            .order('created_at', { ascending: true })

          if (comments) {
            console.log('Updated comments:', comments)
            setCurrentPost(prev => ({
              ...prev,
              comments
            }))

          }
        }
      )
      .subscribe(status => {
        console.log(`Subscription status for post ${post.id}:`, status)
      })

    // Initialize current reactions
    const initialCounts = post.reactions.reduce((acc, r) => ({
      ...acc,
      [r.emoji]: (acc[r.emoji] || 0) + 1
    }), {} as Record<string, number>)
    
    setCurrentReactions(initialCounts)
    setChannel(channel)

    return () => {
      console.log(`Cleaning up realtime monitoring for post ${post.id}`)
      channel.unsubscribe()
    }
  }, [post.id])

  const handleReaction = async (emoji: string) => {
    if (!session?.currentProfile.id) {
      console.log('No current profile for reaction')
      return
    }

    try {
      console.log('Handling reaction:', {
        postId: post.id,
        profileId: session.currentProfile.id,
        emoji
      })

      const existingReaction = currentPost.reactions.find(
        r => r.emoji === emoji && r.profile.id === session.currentProfile.id
      )

      if (existingReaction) {
        console.log('Removing existing reaction')
        await supabase
          .from('post_reactions')
          .delete()
          .eq('post_id', post.id)
          .eq('profile_id', session.currentProfile.id)
          .eq('emoji', emoji)
      } else {
        console.log('Adding new reaction')
        await supabase
          .from('post_reactions')
          .insert({
            post_id: post.id,
            profile_id: session.currentProfile.id,
            emoji
          })
      }
    } catch (error) {
      console.error('Failed to handle reaction:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update reaction. Please try again."
      })
    }
  }

  const handleComment = async (content: string) => {
    if (!session?.currentProfile.id) {
      console.log('No current profile for comment')
      return
    }

    try {
      console.log('Adding comment:', {
        postId: post.id,
        profileId: session.currentProfile.id,
        content
      })

      await supabase
        .from('post_comments')
        .insert({
          post_id: post.id,
          author_id: session.currentProfile.id,
          content
        })
    } catch (error) {
      console.error('Failed to add comment:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to add comment. Please try again."
      })
    }
  }

  const handleEdit = () => {
    // TODO: Implement edit functionality
    toast({
      title: "Coming Soon",
      description: "Edit functionality will be available soon!"
    })
  }

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from('posts')
        .delete()
        .eq('id', post.id)

      if (error) throw error

      toast({
        title: "Success",
        description: "Post deleted successfully"
      })
    } catch (error) {
      console.error('Failed to delete post:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete post. Please try again."
      })
    }
  }

  return (
    <div className="rounded-xl border bg-card overflow-hidden">
      {currentPost.image_url && (
        <div className="h-48 overflow-hidden">
          <img
            src={currentPost.image_url}
            alt="Post cover"
            className="w-full h-full object-cover"
          />
        </div>
      )}
      <div className="p-6 space-y-4">
        {/* Post Header */}
        <div className="flex items-start justify-between">
          <div className="flex items-start gap-3">
            <Avatar className="h-12 w-12">
              <AvatarImage src={currentPost.author.avatar_url} alt={currentPost.author.display_name} />
            </Avatar>
            <div>
              <h3 className="font-semibold">{currentPost.author.display_name}</h3>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <span>{currentPost.author.role}</span>
                <span>•</span>
                <LiveDate date={currentPost.created_at} />
              </div>
            </div>
          </div>
          {canModifyPost && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MoreHorizontal className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={handleEdit}>
                  <Pencil className="mr-2 h-4 w-4" />
                  Edit Post
                </DropdownMenuItem>
                <DropdownMenuItem 
                  onClick={handleDelete}
                  className="text-red-600 focus:text-red-600"
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  Delete Post
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>

        {/* Post Content */}
        <div className="space-y-2">
          <h2 className="text-xl font-semibold">{currentPost.title}</h2>
          <SafeHTML 
            content={currentPost.content} 
            className="prose prose-sm max-w-none text-muted-foreground"
          />
        </div>

        {/* Likes */}
        <LikedBy users={currentPost.reactions.map(r => ({
          id: r.profile.id,
          name: r.profile.display_name,
          avatar: r.profile.avatar_url
        }))} />

        {/* Actions */}
        <div className="flex items-center gap-4 pt-2 border-t">
          <LikeButton 
            initialReactions={currentPost.reactions.reduce((acc, r) => ({
              ...acc,
              [r.emoji]: (acc[r.emoji] || 0) + 1
            }), {})}
            currentReactions={currentReactions}
            onReact={handleReaction}
          />
          <Button 
            variant="ghost" 
            size="sm" 
            className="gap-2"
            onClick={() => setShowComments(!showComments)}
          >
            <MessageCircle className="h-4 w-4" />
            <span>{currentPost.comments.length} Comments</span>
          </Button>
        </div>

        {/* Comments */}
        {showComments && (
          <CommentSection
            comments={currentPost.comments.map(c => ({
              id: c.id,
              user: {
                name: c.author.display_name,
                avatar: c.author.avatar_url
              },
              content: c.content,
              createdAt: c.created_at
            }))}
            onComment={handleComment}
          />
        )}
      </div>
    </div>
  )
}