import { Channel } from '@/types/channel'
import { delay, paginate } from '.'

// Mock data
export const mockChannels: Channel[] = [
  // Mock channels data
]

// Mock API methods
export const channelsMockApi = {
  getChannels: async () => {
    await delay()
    return {
      data: paginate(mockChannels),
      status: 200
    }
  },

  getChannelBySlug: async (slug: string) => {
    await delay()
    const channel = mockChannels.find(channel => channel.slug === slug)
    return {
      data: channel || null,
      status: channel ? 200 : 404
    }
  }
}