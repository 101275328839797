import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"
import { Users, MessageSquare, Calendar, DollarSign } from "lucide-react"

const featureHighlights = [
  {
    icon: Users,
    title: "Perfis que contam histórias",
    description: "Membros criam perfis com fotos, links e informações ricas."
  },
  {
    icon: MessageSquare,
    title: "Fique por dentro das conversas",
    description: "Crie canais temáticos para discussões importantes."
  },
  {
    icon: Calendar,
    title: "Eventos que aproximam",
    description: "Ofereça webinars, cursos e encontros ao vivo em poucos cliques."
  },
  {
    icon: DollarSign,
    title: "Monetização descomplicada",
    description: "Venda acessos, cursos e conteúdos diretamente na plataforma."
  }
]

export function FeatureHighlightsSection() {
  return (
    <section className="container mx-auto">
      <div className="text-center space-y-4 mb-16">
        <h2 className="text-3xl font-bold">
          Tudo o que você precisa para gerenciar e expandir sua comunidade
        </h2>
        <p className="text-muted-foreground max-w-2xl mx-auto">
          No Memberfy, cada funcionalidade foi pensada para simplificar sua gestão e amplificar suas possibilidades.
        </p>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {featureHighlights.map((feature, i) => (
          <Card key={i} className="bg-card hover:shadow-lg transition-all">
            <CardHeader>
              <div className="h-16 w-16 rounded-xl bg-primary/10 flex items-center justify-center mb-4">
                <feature.icon className="w-8 h-8 text-primary" />
              </div>
              <CardTitle className="text-lg">{feature.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground">{feature.description}</p>
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="mt-12 text-center">
        <Button size="lg" variant="outline" asChild>
          <Link to="/features">Conheça todos os recursos</Link>
        </Button>
      </div>
    </section>
  )
}