import type { Event, CreateEventParams, UpdateEventParams } from '@/types/event'
import type { SDKResponse, PaginationParams, PaginatedResponse } from '../types'
import { BaseModule } from './base'
import { mockApi } from '../mock'

export class EventsModule extends BaseModule {
  /**
   * Get all events with pagination
   */
  async getEvents(params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Event>>> {
    if (this.config.useMocks) {
      return mockApi.events.getEvents()
    }
    return this.get('/events', { params })
  }

  /**
   * Get a specific event by slug
   */
  async getEventBySlug(slug: string): Promise<SDKResponse<Event>> {
    if (this.config.useMocks) {
      return mockApi.events.getEventBySlug(slug)
    }
    return this.get(`/events/${slug}`)
  }

  /**
   * Get event attendees with pagination
   */
  async getEventAttendees(
    eventId: string,
    params?: PaginationParams
  ): Promise<SDKResponse<PaginatedResponse<Event['attendees'][0]>>> {
    if (this.config.useMocks) {
      return mockApi.events.getEventAttendees(eventId, params)
    }
    return this.get(`/events/${eventId}/attendees`, { params })
  }

  /**
   * Create a new event
   */
  async createEvent(data: CreateEventParams): Promise<SDKResponse<Event>> {
    return this.post('/events', data)
  }

  /**
   * Update an existing event
   */
  async updateEvent(id: string, data: UpdateEventParams): Promise<SDKResponse<Event>> {
    if (this.config.useMocks) {
      return mockApi.events.updateEvent(id, data)
    }
    return this.patch(`/events/${id}`, data)
  }

  /**
   * Delete an event
   */
  async deleteEvent(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/events/${id}`)
  }

  /**
   * RSVP to an event
   */
  async rsvpEvent(id: string, status: Event['attendees'][0]['status']): Promise<SDKResponse<Event>> {
    if (this.config.useMocks) {
      return mockApi.events.rsvpEvent(id, status)
    }
    return this.post(`/events/${id}/rsvp`, { status })
  }

  /**
   * Save/unsave an event
   */
  async toggleSaveEvent(id: string): Promise<SDKResponse<Event>> {
    return this.post(`/events/${id}/save`)
  }

  /**
   * Add a comment to an event
   */
  async addComment(id: string, content: string): Promise<SDKResponse<Event>> {
    return this.post(`/events/${id}/comments`, { content })
  }

  /**
   * Get event questions
   */
  async getQuestions(id: string): Promise<SDKResponse<Event['questions']>> {
    return this.get(`/events/${id}/questions`)
  }

  /**
   * Add a question to an event
   */
  async addQuestion(id: string, content: string): Promise<SDKResponse<Event['questions'][0]>> {
    return this.post(`/events/${id}/questions`, { content })
  }

  /**
   * Answer a question
   */
  async answerQuestion(eventId: string, questionId: string, answer: string): Promise<SDKResponse<Event['questions'][0]>> {
    return this.post(`/events/${eventId}/questions/${questionId}/answer`, { answer })
  }

  /**
   * Send a chat message
   */
  async sendChatMessage(id: string, content: string): Promise<SDKResponse<Event['chatMessages'][0]>> {
    return this.post(`/events/${id}/chat`, { content })
  }
}