import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '@/providers/AuthProvider'
import { useToast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

export function Login() {
  const navigate = useNavigate()
  const { signIn } = useAuthContext()
  const { toast } = useToast()
  
  const [subdomain, setSubdomain] = useState('app')
  const [email, setEmail] = useState('david@memberfy.net')
  const [password, setPassword] = useState('Mudar@123')
  const [loading, setLoading] = useState(false)

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)

    try {
      await signIn(email, password, subdomain)
      navigate('/dashboard')
    } catch (error: any) {
      console.error('Login failed:', error)
      
      let message = 'Please check your credentials and try again.'
      
      if (error.message === 'Community not found') {
        message = 'Invalid community subdomain.'
      } else if (error.message === 'No profile found for this community') {
        message = 'You don\'t have access to this community.'
      }

      toast({
        variant: "destructive",
        title: "Login failed",
        description: message
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="container max-w-[400px] mx-auto">
      <Card>
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center">Login</CardTitle>
          <CardDescription className="text-center">
            Enter your credentials to access your community
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleLogin} className="space-y-4">
            {/* Subdomain Input */}
            <div className="space-y-2">
              <Label htmlFor="subdomain">Community</Label>
              <div className="flex">
                <Input
                  id="subdomain"
                  value={subdomain}
                  onChange={(e) => setSubdomain(e.target.value)}
                  className="rounded-r-none"
                  disabled={loading}
                />
                <div className="flex items-center bg-muted px-3 rounded-r-md border border-l-0 border-input">
                  .memberfy.net
                </div>
              </div>
            </div>

            {/* Email Input */}
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="m@example.com"
                required
                disabled={loading}
              />
            </div>

            {/* Password Input */}
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={loading}
              />
            </div>

            {/* Submit Button */}
            <Button 
              type="submit" 
              className="w-full" 
              disabled={loading}
            >
              {loading ? 'Signing in...' : 'Sign In'}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  )
}