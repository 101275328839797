interface CourseDescriptionProps {
  description: string
}

export function CourseDescription({ description }: CourseDescriptionProps) {
  return (
    <section className="space-y-4">
      <h2 className="text-2xl font-bold">Course Description</h2>
      <div className="prose max-w-none">
        <p className="whitespace-pre-line">{description}</p>
      </div>
    </section>
  )
}