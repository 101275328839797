import { useEffect, useState } from 'react'
import { Users, Shield, Video, Check, X } from 'lucide-react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { formatBRL } from '@/lib/currency'
import { getPricingPlans } from '@/api/pricing'
import type { Plan } from '@/types/pricing'

interface PricingPlansProps {
  billingPeriod: 'monthly' | 'annual'
}

export function PricingPlans({ billingPeriod }: PricingPlansProps) {
  const [plans, setPlans] = useState<Plan[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getPricingPlans().then(plans => {
      setPlans(plans)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className="container mx-auto">
      <div className="grid md:grid-cols-3 gap-8">
        {plans.map((plan) => (
          <Card 
            key={plan.id}
            className={cn(
              "relative",
              plan.popular && "border-primary scale-105 shadow-lg"
            )}
          >
            {plan.popular && (
              <Badge 
                className="absolute -top-3 right-4 bg-primary text-primary-foreground"
              >
                Mais popular
              </Badge>
            )}
            <CardHeader>
              <CardTitle>{plan.name}</CardTitle>
              <CardDescription>{plan.description}</CardDescription>
              <div className="mt-4">
                <span className="text-3xl font-bold">
                  {formatBRL(billingPeriod === 'monthly' 
                    ? plan.price.monthly 
                    : plan.price.annual)}
                </span>
                <span className="text-muted-foreground">
                  /{billingPeriod === 'monthly' ? 'mês' : 'ano'}
                </span>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                {/* Limites do Plano */}
                <div className="space-y-2">
                  <h4 className="text-sm font-medium">Limites do plano:</h4>
                  <ul className="space-y-2">
                    <li className="flex items-center gap-2">
                      <Users className="h-4 w-4 text-primary" />
                      <span>Até {plan.limits.members} membros</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <Shield className="h-4 w-4 text-primary" />
                      <span>{plan.limits.admins} administradores</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <Video className="h-4 w-4 text-primary" />
                      <span>{plan.limits.liveMembers} membros simultâneos em live</span>
                    </li>
                  </ul>
                </div>

                <Separator />

                {/* Destaques do Plano */}
                <div className="space-y-2">
                  <h4 className="text-sm font-medium">Inclui:</h4>
                  <ul className="space-y-2">
                    {plan.highlights.map((highlight, index) => (
                      <li key={index} className="flex items-center gap-2">
                        {highlight.startsWith('Sem') ? (
                          <X className="h-4 w-4 text-destructive" />
                        ) : (
                          <Check className="h-4 w-4 text-primary" />
                        )}
                        <span>{highlight}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <Button className="w-full" size="lg">
                  Começar agora
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}