import type { CommunitySettings, Theme } from '@/types/community'
import type { SDKResponse } from '../types'
import { BaseModule } from './base'

export class CommunityModule extends BaseModule {
  /**
   * Get community settings
   */
  async getSettings(): Promise<SDKResponse<CommunitySettings>> {
    return this.get('/community/settings')
  }

  /**
   * Update community settings
   */
  async updateSettings(settings: Partial<CommunitySettings>): Promise<SDKResponse<CommunitySettings>> {
    return this.patch('/community/settings', settings)
  }

  /**
   * Get available themes
   */
  async getThemes(): Promise<SDKResponse<Theme[]>> {
    return this.get('/community/themes')
  }

  /**
   * Update community theme
   */
  async updateTheme(theme: Theme): Promise<SDKResponse<CommunitySettings>> {
    return this.patch('/community/theme', { theme })
  }
}