import {
  Card,
  CardContent,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"
import { Quote } from "lucide-react"

const testimonials = [
  {
    name: "Maria Silva",
    role: "Criadora de Conteúdo",
    avatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=Maria",
    quote: "Finalmente encontrei uma plataforma que entende o que minha comunidade precisa. Simples de usar e cheia de recursos!"
  },
  {
    name: "João Santos",
    role: "CEO Startup",
    avatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=Joao",
    quote: "Eu nunca imaginei que seria tão fácil transformar seguidores em uma rede engajada. O Memberfy mudou tudo para mim."
  }
]

export function TestimonialsSection() {
  return (
    <section className="container mx-auto">
      <div className="relative py-16 bg-gradient-to-b from-primary/5 to-primary/10 rounded-3xl">
        <div className="text-center space-y-4 mb-16">
          <h2 className="text-3xl font-bold">
            Veja como o Memberfy transformou comunidades ao redor do mundo
          </h2>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Não é só sobre tecnologia. É sobre pessoas.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto px-6">
          {testimonials.map((testimonial, i) => (
            <Card key={i} className="bg-card relative">
              <CardContent className="pt-6">
                <Quote className="absolute top-6 right-6 h-8 w-8 text-primary/20" />
                <div className="flex items-center gap-6">
                  <img
                    src={testimonial.avatar}
                    alt={testimonial.name}
                    className="w-20 h-20 rounded-full border-4 border-primary/10"
                  />
                  <div>
                    <p className="font-semibold text-lg">{testimonial.name}</p>
                    <p className="text-sm text-muted-foreground">{testimonial.role}</p>
                  </div>
                </div>
                <blockquote className="mt-6 text-lg leading-relaxed">
                  "{testimonial.quote}"
                </blockquote>
              </CardContent>
            </Card>
          ))}
        </div>
        <div className="mt-12 text-center">
          <Button size="lg" variant="secondary" asChild>
            <Link to="/signup">Junte-se a eles</Link>
          </Button>
        </div>
      </div>
    </section>
  )
}