import type { SDKConfig } from './types'
import { PricingModule } from './modules/pricing'
import { EventsModule } from './modules/events'
import { CoursesModule } from './modules/courses'
import { CommunityModule } from './modules/community'
import { NavigationModule } from './modules/navigation'
import { PostsModule } from './modules/posts'
import { ChannelsModule } from './modules/channels'
import { MembersModule } from './modules/members'

export class MemberfySDK {
  public pricing: PricingModule
  public events: EventsModule
  public courses: CoursesModule
  public community: CommunityModule
  public navigation: NavigationModule
  public posts: PostsModule
  public channels: ChannelsModule
  public members: MembersModule

  constructor(config: SDKConfig = {}) {
    // Set default config
    config = {
      baseUrl: '/api',
      useMocks: true, // Enable mocks by default
      ...config
    }

    this.pricing = new PricingModule(config)
    this.events = new EventsModule(config)
    this.courses = new CoursesModule(config)
    this.community = new CommunityModule(config)
    this.navigation = new NavigationModule(config)
    this.posts = new PostsModule(config)
    this.channels = new ChannelsModule(config)
    this.members = new MembersModule(config)
  }
}

// Export default instance with default config
export default new MemberfySDK()

// Export types
export * from './types'
export * from './modules/pricing'
export * from './modules/events'
export * from './modules/courses'
export * from './modules/community'
export * from './modules/navigation'
export * from './modules/posts'
export * from './modules/channels'
export * from './modules/members'