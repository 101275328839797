import { supabase } from '@/config/supabase'
import type { AppGroup } from '@/types/navigation'

export const navigationService = {
  async getMenuGroups(communityId: string) {
    console.log('Fetching menu groups for community:', communityId)

    // Get groups with their spaces
    const { data: groups, error: groupsError } = await supabase
      .from('groups')
      .select(`
        id,
        name,
        slug,
        spaces (
          id,
          slug,
          name,
          type,
          settings
        )
      `)
      .eq('community_id', communityId)
      .order('name')

    if (groupsError) {
      console.error('Error fetching groups:', groupsError)
      throw groupsError
    }

    console.log('Raw groups data:', groups)

    // Transform the data to match our AppGroup type
    const menuGroups: AppGroup[] = groups.map(group => ({
      id: group.id,
      name: group.name,
      slug: group.slug,
      spaces: group.spaces.map(space => ({
        id: space.id,
        icon: space.settings?.icon || getDefaultIcon(space.type),
        label: space.name,
        slug: space.slug,
        module: getModuleFromType(space.type)
      }))
    }))

    console.log('Transformed menu groups:', menuGroups)
    return menuGroups
  }
}

// Helper function to get default icon based on space type
function getDefaultIcon(type: string): string {
  switch (type) {
    case 'posts':
      return 'MessageSquare'
    case 'events':
      return 'Calendar'
    case 'courses':
      return 'GraduationCap'
    case 'channels':
      return 'MessagesSquare'
    case 'members':
      return 'Users'
    default:
      return 'MessageSquare'
  }
}

// Helper function to get module name from space type
function getModuleFromType(type: string): AppGroup['spaces'][0]['module'] {
  switch (type) {
    case 'posts':
      return 'Posts'
    case 'events':
      return 'Events'
    case 'courses':
      return 'Courses'
    case 'channels':
      return 'Channels'
    case 'members':
      return 'Members'
    default:
      return 'Posts'
  }
}