import { Avatar, AvatarImage } from "@/components/ui/avatar"

interface User {
  id: string
  name: string
  avatar: string
}

interface LikedByProps {
  users: User[]
}

export function LikedBy({ users }: LikedByProps) {
  if (users.length === 0) return null

  // Create a map to deduplicate users by ID
  const uniqueUsers = Array.from(
    new Map(users.map(user => [user.id, user])).values()
  ).slice(0, 3) // Only show first 3 unique users

  return (
    <div className="flex items-center gap-2">
      <div className="flex -space-x-2">
        {uniqueUsers.map((user) => (
          <Avatar key={user.id} className="h-6 w-6 border-2 border-background">
            <AvatarImage src={user.avatar} alt={user.name} />
          </Avatar>
        ))}
      </div>
      <span className="text-sm text-muted-foreground">
        Liked by {uniqueUsers[0].name}
        {uniqueUsers.length > 1 && ` and ${uniqueUsers.length - 1} others`}
      </span>
    </div>
  )
}
