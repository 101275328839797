import { Star, Users, Clock, BookOpen } from 'lucide-react'
import { Badge } from '@/components/ui/badge'
import type { Course } from '@/types/course'

interface CourseHeroProps {
  course: Course
  totalLectures: number
}

export function CourseHero({ course, totalLectures }: CourseHeroProps) {
  return (
    <div className="space-y-6">
      <div>
        <div className="flex flex-wrap gap-2 mb-4">
          <Badge variant={course.type === 'live' ? 'destructive' : 'secondary'}>
            {course.type === 'ondemand' ? 'On-demand' : 
             course.type === 'live' ? 'Live' : 'Presential'}
          </Badge>
          <Badge 
            variant="secondary" 
            className="bg-primary/10 text-primary hover:bg-primary/20"
          >
            {course.level}
          </Badge>
          {course.status === 'limited' && (
            <Badge variant="secondary">Limited Spots</Badge>
          )}
        </div>
        <h1 className="text-3xl font-bold mb-4">{course.title}</h1>
        <p className="text-muted-foreground max-w-3xl">{course.shortDescription}</p>
      </div>

      <div className="flex flex-wrap gap-4">
        <div className="flex items-center gap-1">
          <Star className="h-4 w-4 fill-yellow-400 text-yellow-400" />
          <span className="font-medium">{course.rating.average}</span>
          <span className="text-muted-foreground">
            ({course.rating.total} reviews)
          </span>
        </div>
        <div className="flex items-center gap-1 text-muted-foreground">
          <Users className="h-4 w-4" />
          <span>{course.enrollments} students</span>
        </div>
        <div className="flex items-center gap-1 text-muted-foreground">
          <Clock className="h-4 w-4" />
          <span>{course.duration.total} hours total</span>
        </div>
        <div className="flex items-center gap-1 text-muted-foreground">
          <BookOpen className="h-4 w-4" />
          <span>{totalLectures} lectures</span>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <img
          src={course.instructor.avatar}
          alt={course.instructor.name}
          className="w-12 h-12 rounded-full"
        />
        <div>
          <h3 className="font-medium">{course.instructor.name}</h3>
          <p className="text-sm text-muted-foreground">
            {course.instructor.title}
          </p>
        </div>
      </div>
    </div>
  )
}