import { Course } from '@/types/course'
import { RegularCourseCard } from './RegularCourseCard'

interface CourseListProps {
  courses: Course[]
  groupSlug: string
  spaceSlug: string
}

export function CourseList({ courses, groupSlug, spaceSlug }: CourseListProps) {
  if (courses.length === 0) {
    return (
      <div className="text-center py-8 text-muted-foreground">
        No courses found
      </div>
    )
  }

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">All Courses</h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
        {courses.map((course) => (
          <RegularCourseCard
            key={course.id}
            course={course}
            groupSlug={groupSlug}
            spaceSlug={spaceSlug}
          />
        ))}
      </div>
    </div>
  )
}