import { useState, useEffect } from 'react'
import { authService } from '@/services/auth'
import type { AuthSession } from '@/types/auth'

export function useAuth() {
  const [session, setSession] = useState<AuthSession | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Load session from localStorage
    const storedSession = localStorage.getItem('memberfy:auth')
    if (storedSession) {
      setSession(JSON.parse(storedSession))
    }
    setLoading(false)

    // Subscribe to auth changes
    const { data: { subscription } } = authService.onAuthStateChange((session) => {
      setSession(session)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const signIn = async (email: string, password: string, subdomain: string) => {
    try {
      const session = await authService.signIn({ email, password, subdomain })
      localStorage.setItem('memberfy:auth', JSON.stringify(session))
      setSession(session)
      return session
    } catch (error) {
      console.error('Sign in error:', error)
      throw error
    }
  }

  const signOut = async () => {
    try {
      await authService.signOut()
      localStorage.removeItem('memberfy:auth')
      setSession(null)
    } catch (error) {
      console.error('Sign out error:', error)
      throw error
    }
  }

  return {
    session,
    loading,
    signIn,
    signOut,
    isAuthenticated: !!session
  }
}