import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"

const faqItems = [
  {
    question: "Posso mudar de plano a qualquer momento?",
    answer: "Sim! Você pode atualizar ou fazer downgrade do seu plano a qualquer momento. As mudanças serão refletidas na sua próxima fatura."
  },
  {
    question: "Como funciona o período de teste?",
    answer: "Oferecemos 14 dias de teste gratuito em todos os planos, sem necessidade de cartão de crédito. Durante este período, você terá acesso completo a todas as funcionalidades do plano escolhido para avaliar se atende às suas necessidades."
  },
  {
    question: "Existe garantia de reembolso?",
    answer: "Sim! Oferecemos garantia de reembolso de 30 dias. Se por qualquer motivo você decidir que o Memberfy não é a solução ideal para sua comunidade, basta nos avisar e faremos o reembolso integral, sem questionamentos."
  },
  {
    question: "O que acontece se eu ultrapassar o limite do meu plano?",
    answer: "Nós notificaremos você quando estiver próximo de atingir os limites. Se você ultrapassar, conversaremos sobre um upgrade para um plano mais adequado às suas necessidades."
  },
  {
    question: "Posso agendar uma consultoria com a equipe?",
    answer: "Com certeza! Se você está considerando assinar o plano Professional ou superior, pode agendar uma consultoria com nossa equipe comercial. Para clientes atuais, basta entrar em contato diretamente com nosso suporte."
  },
  {
    question: "Vocês oferecem suporte para migração?",
    answer: "Sim! Oferecemos serviços de migração para facilitar sua transição para o Memberfy. Isso inclui a transferência de assinaturas ativas, informações de pagamento e conteúdo de cursos, dependendo do seu plano."
  },
  {
    question: "Que tipo de suporte vocês oferecem?",
    answer: "Ao se cadastrar no Memberfy, você ganha acesso à nossa comunidade privada. Lá, você encontra workshops ao vivo, chamadas semanais de coaching, horários de plantão, sessões de Q&A, entrevistas com especialistas e muito mais!"
  },
  {
    question: "Há custos adicionais além do plano?",
    answer: "Além do valor do plano, existe apenas a taxa de processamento de pagamentos de 6,99% + R$ 2,49 por venda realizada na plataforma."
  }
]

export function PricingFAQ() {
  return (
    <div className="container mx-auto max-w-3xl">
      <div className="space-y-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold">Perguntas frequentes</h2>
          <p className="text-muted-foreground">
            Tire suas dúvidas sobre nossos planos e funcionalidades
          </p>
        </div>

        <Accordion type="single" collapsible className="w-full">
          {faqItems.map((item, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger>{item.question}</AccordionTrigger>
              <AccordionContent>{item.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  )
}