import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

const whoUsesProfiles = [
  {
    title: "Influenciadores",
    description: "Conecte-se com seus seguidores e transforme fãs em uma comunidade apaixonada."
  },
  {
    title: "Startups",
    description: "Consolide sua marca, engaje clientes e crie conversas que geram valor."
  },
  {
    title: "Profissionais",
    description: "Compartilhe conhecimento com cursos e conteúdos que inspiram."
  },
  {
    title: "Empresas",
    description: "Centralize ações, fidelize clientes e transforme interações em resultados."
  },
  {
    title: "Grandes Organizações",
    description: "Gerencie grandes comunidades com segurança, insights e automações."
  }
]

export function WhoUsesSection() {
  return (
    <section className="container mx-auto">
      <div className="text-center space-y-4 mb-16">
        <h2 className="text-3xl font-bold">
          Feito para quem quer criar comunidades que fazem a diferença
        </h2>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {whoUsesProfiles.map((profile, i) => (
          <Card key={i} className="bg-card">
            <CardHeader>
              <CardTitle>{profile.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground">{profile.description}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </section>
  )
}