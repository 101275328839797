import { useState, useEffect } from 'react'
import { Smile } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/lib/utils'

interface Reaction {
  emoji: string
  count: number
  reacted: boolean
}

interface ReactionButtonProps {
  initialReactions?: Record<string, number>
  currentReactions?: Record<string, number>
  onReact?: (emoji: string, reacting: boolean) => void
  size?: 'sm' | 'default'
}

const AVAILABLE_REACTIONS = ['👍', '❤️', '😂', '😮', '😢', '😡', '🎉', '👀']

export function LikeButton({ 
  initialReactions = {},
  currentReactions,
  onReact,
  size = 'default'
}: ReactionButtonProps) {
  const [reactions, setReactions] = useState<Record<string, Reaction>>(() => {
    return AVAILABLE_REACTIONS.reduce((acc, emoji) => ({
      ...acc,
      [emoji]: {
        emoji,
        count: initialReactions[emoji] || 0,
        reacted: false
      }
    }), {})
  })
  const [open, setOpen] = useState(false)

  // Update reactions when currentReactions changes
  useEffect(() => {
    if (currentReactions) {
      setReactions(prev => {
        const updated = { ...prev }
        // Reset all counts first
        Object.keys(updated).forEach(emoji => {
          updated[emoji] = {
            ...updated[emoji],
            count: 0
          }
        })
        // Update with new counts
        Object.entries(currentReactions).forEach(([emoji, count]) => {
          if (updated[emoji]) {
            updated[emoji] = {
              ...updated[emoji],
              count
            }
          }
        })
        return updated
      })
    }
  }, [currentReactions])

  const handleReaction = (emoji: string) => {
    setReactions(prev => {
      const reaction = prev[emoji]
      const reacting = !reaction.reacted
      
      return {
        ...prev,
        [emoji]: {
          ...reaction,
          count: reaction.count + (reacting ? 1 : -1),
          reacted: reacting
        }
      }
    })

    onReact?.(emoji, !reactions[emoji].reacted)
    setOpen(false) // Close popover after selecting an emoji
  }

  const activeReactions = Object.values(reactions).filter(r => r.count > 0)

  return (
    <div className="flex items-center gap-1">
      {activeReactions.length > 0 && (
        <div className="flex -space-x-1 items-center">
          {activeReactions.map(reaction => (
            <Button
              key={reaction.emoji}
              variant={reaction.reacted ? "secondary" : "ghost"}
              size={size}
              className={cn(
                "rounded-full px-2 text-sm",
                reaction.reacted && "bg-accent"
              )}
              onClick={() => handleReaction(reaction.emoji)}
            >
              <span className="mr-1 text-base">{reaction.emoji}</span>
              <span>{reaction.count}</span>
            </Button>
          ))}
        </div>
      )}

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size={size}
            className="rounded-full px-2"
          >
            <Smile className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-2">
          <div className="flex gap-1 flex-wrap">
            {AVAILABLE_REACTIONS.map(emoji => (
              <Button
                key={emoji}
                variant="ghost"
                size="sm"
                className="hover:bg-accent rounded-full px-2 cursor-pointer"
                onClick={() => handleReaction(emoji)}
              >
                <span className="text-lg">{emoji}</span>
              </Button>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}