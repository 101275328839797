import { Link } from "react-router-dom"
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
import { cn } from "@/lib/utils"

export function PublicNavbar() {
  return (
    <header className="border-b">
      <div className="container mx-auto px-4 py-4">
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <Link to="/" className="text-lg font-bold">
                Logo
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link 
                to="/" 
                className={cn(navigationMenuTriggerStyle(), "cursor-pointer")}
              >
                Home
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link 
                to="/pricing" 
                className={cn(navigationMenuTriggerStyle(), "cursor-pointer")}
              >
                Preços
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link 
                to="/about" 
                className={cn(navigationMenuTriggerStyle(), "cursor-pointer")}
              >
                Sobre
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link 
                to="/login" 
                className={cn(navigationMenuTriggerStyle(), "cursor-pointer")}
              >
                Login
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link 
                to="/signup" 
                className={cn(navigationMenuTriggerStyle(), "cursor-pointer")}
              >
                Cadastre-se
              </Link>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </header>
  )
}