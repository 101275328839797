import { Link } from "react-router-dom"
import {
  Card,
  CardContent,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"

export function CTASection() {
  return (
    <section className="container mx-auto">
      <Card className="bg-primary text-primary-foreground">
        <CardContent className="pt-6">
          <div className="text-center space-y-6">
            <h2 className="text-3xl font-bold">
              Pronto para transformar suas ideias em uma comunidade incrível?
            </h2>
            <p className="text-primary-foreground/80">
              Começar é fácil. A gente cuida do resto.
            </p>
            <div className="flex gap-4 justify-center">
              <Button size="lg" variant="secondary" asChild>
                <Link to="/signup">Teste grátis agora</Link>
              </Button>
              <Button size="lg" variant="outline" className="bg-transparent" asChild>
                <Link to="/contact">Converse com nossa equipe</Link>
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </section>
  )
}