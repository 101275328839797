import { supabase } from '@/config/supabase'
import type { AuthSession } from '@/types/auth'

interface SignInParams {
  email: string
  password: string
  subdomain: string
}

export const authService = {
  async signIn({ email, password, subdomain }: SignInParams) {
    try {
      // 1. Sign in with Supabase Auth
      const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
        email,
        password
      })

      if (authError) {
        throw authError
      }

      if (!authData.user) {
        throw new Error('No user data returned')
      }

      // 2. Get community by subdomain
      const { data: communityData, error: communityError } = await supabase
        .from('communities')
        .select('*, community_subscriptions(plan_id, status)')
        .eq('slug', subdomain)
        .single()

      if (communityError || !communityData) {
        throw new Error('Community not found')
      }

      // 3. Get account details
      const { data: accountData, error: accountError } = await supabase
        .from('accounts')
        .select('*')
        .eq('id', authData.user.id)
        .single()

      if (accountError || !accountData) {
        throw new Error('Account not found')
      }

      // 4. Get community profiles
      const { data: profilesData, error: profilesError } = await supabase
        .from('community_profiles')
        .select('*')
        .eq('community_id', communityData.id)
        .eq('account_id', accountData.id)

      if (profilesError) {
        throw profilesError
      }

      if (!profilesData.length) {
        throw new Error('No profile found for this community')
      }

      // 5. Build session object
      const session: AuthSession = {
        account: accountData,
        community: communityData,
        profiles: profilesData,
        currentProfile: profilesData[0] // Default to first profile
      }

      return session
    } catch (error) {
      console.error('Authentication error:', error)
      throw error
    }
  },

  async signOut() {
    const { error } = await supabase.auth.signOut()
    if (error) throw error
  },

  async getCurrentSession() {
    const { data: { session }, error } = await supabase.auth.getSession()
    if (error) throw error
    return session
  },

  onAuthStateChange(callback: (session: AuthSession | null) => void) {
    return supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT') {
        callback(null)
        return
      }

      if (session?.user) {
        // Get stored community from localStorage
        const storedSession = localStorage.getItem('memberfy:auth')
        if (storedSession) {
          callback(JSON.parse(storedSession))
        }
      }
    })
  }
}