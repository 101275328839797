import { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { hslToHex, hexToHSL } from '@/lib/color'

interface ColorPickerProps {
  label: string
  value: string
  onChange: (value: string) => void
}

export function ColorPicker({ label, value, onChange }: ColorPickerProps) {
  const [hexColor, setHexColor] = useState(() => hslToHex(value))

  const handleHexChange = (hex: string) => {
    setHexColor(hex)
    onChange(hexToHSL(hex))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hex = e.target.value
    if (/^#[0-9A-F]{6}$/i.test(hex)) {
      handleHexChange(hex)
    }
  }

  return (
    <div className="space-y-2">
      <Label>{label}</Label>
      <div className="flex gap-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="w-[60px] h-[35px] p-0"
              style={{ backgroundColor: hexColor }}
            >
              <span className="sr-only">Pick a color</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-3">
            <HexColorPicker color={hexColor} onChange={handleHexChange} />
          </PopoverContent>
        </Popover>
        <Input
          value={hexColor}
          onChange={handleInputChange}
          className="font-mono"
          placeholder="#000000"
        />
      </div>
    </div>
  )
}