import { useEffect, useState } from "react"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Link } from "react-router-dom"
import { Check, X } from "lucide-react"
import { formatBRL } from '@/lib/currency'
import { getPricingPlans } from '@/api/pricing'
import type { Plan } from '@/types/pricing'

export function PricingSection() {
  const [plans, setPlans] = useState<Plan[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getPricingPlans().then(allPlans => {
      // Filtra apenas os planos Starter e Influencer
      const filteredPlans = allPlans.filter(
        plan => plan.id === 'starter' || plan.id === 'influencer'
      )
      setPlans(filteredPlans)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <section className="container mx-auto">
      <div className="text-center space-y-4 mb-16">
        <h2 className="text-3xl font-bold">Planos para cada momento da sua comunidade</h2>
        <p className="text-muted-foreground max-w-2xl mx-auto">
          Escolha o plano ideal para o momento atual da sua comunidade e cresça com a gente
        </p>
      </div>
      <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
        {plans.map((plan) => (
          <Card key={plan.id} className={plan.popular ? "border-primary relative" : ""}>
            {plan.popular && (
              <Badge className="absolute -top-3 right-4 bg-primary text-primary-foreground">
                Popular
              </Badge>
            )}
            <CardHeader>
              <CardTitle>{plan.name}</CardTitle>
              <CardDescription>{plan.description}</CardDescription>
              <div className="mt-4">
                <span className="text-3xl font-bold">
                  {formatBRL(plan.price.monthly)}
                </span>
                <span className="text-muted-foreground">/mês</span>
              </div>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2">
                {plan.highlights.map((feature, j) => (
                  <li key={j} className="flex items-center gap-2">
                    {feature.startsWith('Sem') ? (
                      <X className="h-4 w-4 text-destructive" />
                    ) : (
                      <Check className="h-4 w-4 text-primary" />
                    )}
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <Button 
                className="w-full mt-6" 
                variant={plan.popular ? "default" : "outline"}
              >
                Começar agora
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="mt-8 text-center">
        <Button variant="outline" asChild>
          <Link to="/pricing">Compare todos os planos</Link>
        </Button>
      </div>
    </section>
  )
}