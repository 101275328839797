import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { Avatar, AvatarImage } from "@/components/ui/avatar"
import { LikeButton } from "@/components/shared/LikeButton"
import { CommentSection } from "@/components/shared/CommentSection"

// Mock data
const mockProfile = {
  id: "1",
  name: "John Doe",
  avatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=John",
  coverImage: "https://images.unsplash.com/photo-1707343843437-caacff5cfa74",
  role: "Senior Software Engineer",
  company: "Tech Corp",
  location: "San Francisco, CA",
  bio: "Passionate about building great software and helping others learn.",
  stats: {
    followers: 1234,
    following: 567,
    posts: 89
  },
  skills: ["React", "TypeScript", "Node.js", "AWS", "Docker"],
  experience: [
    {
      id: "1",
      role: "Senior Software Engineer",
      company: "Tech Corp",
      period: "2020 - Present",
      description: "Leading the frontend team and architecting scalable solutions."
    },
    {
      id: "2",
      role: "Software Engineer",
      company: "StartupX",
      period: "2018 - 2020",
      description: "Developed and maintained multiple client applications."
    }
  ],
  recentPosts: [
    {
      id: "1",
      content: "Just launched a new feature in production! 🚀",
      createdAt: "2h ago",
      likes: 42,
      comments: 5
    }
  ]
}

export function Profile() {
  return (
    <div className="space-y-8">
      {/* Cover Image & Profile Info */}
      <div className="relative rounded-lg overflow-hidden">
        <img
          src={mockProfile.coverImage}
          alt="Cover"
          className="w-full h-48 object-cover"
        />
        <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/60 to-transparent">
          <div className="flex items-end gap-4">
            <Avatar className="h-24 w-24 border-4 border-background">
              <AvatarImage src={mockProfile.avatar} alt={mockProfile.name} />
            </Avatar>
            <div className="flex-1 text-white mb-2">
              <h1 className="text-2xl font-bold">{mockProfile.name}</h1>
              <p>{mockProfile.role} at {mockProfile.company}</p>
            </div>
            <div className="flex gap-2">
              <Button variant="default">Follow</Button>
              <Button variant="secondary" className="bg-background/20 hover:bg-background/30">
                Message
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Stats */}
      <div className="grid grid-cols-3 gap-4 text-center">
        <div className="rounded-lg border bg-card p-4">
          <div className="text-2xl font-bold">{mockProfile.stats.followers}</div>
          <div className="text-sm text-muted-foreground">Followers</div>
        </div>
        <div className="rounded-lg border bg-card p-4">
          <div className="text-2xl font-bold">{mockProfile.stats.following}</div>
          <div className="text-sm text-muted-foreground">Following</div>
        </div>
        <div className="rounded-lg border bg-card p-4">
          <div className="text-2xl font-bold">{mockProfile.stats.posts}</div>
          <div className="text-sm text-muted-foreground">Posts</div>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-3">
        {/* Left Column */}
        <div className="space-y-6">
          {/* About */}
          <div className="rounded-lg border bg-card p-6">
            <h2 className="text-lg font-semibold mb-4">About</h2>
            <div className="space-y-4">
              <p className="text-sm">{mockProfile.bio}</p>
              <div className="text-sm">
                <div className="flex items-center gap-2">
                  <span className="text-muted-foreground">Location:</span>
                  {mockProfile.location}
                </div>
              </div>
            </div>
          </div>

          {/* Skills */}
          <div className="rounded-lg border bg-card p-6">
            <h2 className="text-lg font-semibold mb-4">Skills</h2>
            <div className="flex flex-wrap gap-2">
              {mockProfile.skills.map((skill) => (
                <div
                  key={skill}
                  className="px-3 py-1 rounded-full bg-accent text-accent-foreground text-sm"
                >
                  {skill}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="md:col-span-2 space-y-6">
          {/* Experience */}
          <div className="rounded-lg border bg-card p-6">
            <h2 className="text-lg font-semibold mb-4">Experience</h2>
            <div className="space-y-6">
              {mockProfile.experience.map((exp) => (
                <div key={exp.id} className="space-y-2">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="font-medium">{exp.role}</h3>
                      <p className="text-sm text-muted-foreground">
                        {exp.company}
                      </p>
                    </div>
                    <span className="text-sm text-muted-foreground">
                      {exp.period}
                    </span>
                  </div>
                  <p className="text-sm">{exp.description}</p>
                  {exp.id !== mockProfile.experience[mockProfile.experience.length - 1].id && (
                    <Separator className="mt-4" />
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Recent Posts */}
          <div className="rounded-lg border bg-card p-6">
            <h2 className="text-lg font-semibold mb-4">Recent Posts</h2>
            <div className="space-y-6">
              {mockProfile.recentPosts.map((post) => (
                <div key={post.id} className="space-y-4">
                  <p>{post.content}</p>
                  <div className="flex items-center gap-4 text-sm text-muted-foreground">
                    <span>{post.createdAt}</span>
                    <LikeButton initialLikes={post.likes} />
                    <span>{post.comments} comments</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}