import { useState, useEffect } from 'react'
import { formatDate } from '@/lib/date'

export function useLiveDate(date: string | Date) {
  const [formattedDate, setFormattedDate] = useState(() => formatDate(date))

  useEffect(() => {
    // Update immediately when date prop changes
    setFormattedDate(formatDate(date))

    // Calculate update interval based on how old the date is
    const now = new Date()
    const timestamp = new Date(date)
    const diffInSeconds = Math.floor((now.getTime() - timestamp.getTime()) / 1000)

    // Update every:
    // - 1 second if less than a minute old
    // - 30 seconds if less than an hour old
    // - 1 minute if less than a day old
    // - No updates if older than a day
    let interval: number
    if (diffInSeconds < 60) {
      interval = 1000 // every second
    } else if (diffInSeconds < 3600) {
      interval = 30000 // every 30 seconds
    } else if (diffInSeconds < 86400) {
      interval = 60000 // every minute
    } else {
      return // no updates needed
    }

    const timer = setInterval(() => {
      setFormattedDate(formatDate(date))
    }, interval)

    return () => clearInterval(timer)
  }, [date])

  return formattedDate
}