import { useState } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { RichTextEditor } from "@/components/editor/RichTextEditor"
import { useAuthContext } from "@/providers/AuthProvider"
import { useToast } from "@/components/ui/use-toast"
import { supabase } from "@/config/supabase"

interface CreatePostDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  groupSlug: string
  spaceSlug: string
}

export function CreatePostDialog({ 
  open, 
  onOpenChange,
  groupSlug,
  spaceSlug 
}: CreatePostDialogProps) {
  const { session } = useAuthContext()
  const { toast } = useToast()
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const [loading, setLoading] = useState(false)

  // Check if user has permission to create posts
  const canCreatePosts = session?.currentProfile.role === 'admin' || 
                        session?.currentProfile.role === 'moderator'

  if (!canCreatePosts) {
    return null
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!session?.community.id || !session?.currentProfile.id) {
      console.error('Missing required data')
      return
    }

    if (!title.trim() || !content.trim()) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Please fill in all required fields."
      })
      return
    }

    setLoading(true)

    try {
      // First get the space ID
      const { data: spaceData, error: spaceError } = await supabase
        .from('spaces')
        .select('id, groups!inner(id, slug)')
        .eq('groups.slug', groupSlug)
        .eq('slug', spaceSlug)
        .single();

      if (spaceError || !spaceData) {
        console.log("groupSlug", groupSlug);
        console.log("spaceSlug", spaceSlug);
        console.log("spaceData", spaceData);
        throw new Error('Failed to get space ID')
      }

      // Generate slug from title
      const slug = title
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-')

      // Create the post
      const { error: postError } = await supabase
        .from('posts')
        .insert({
          community_id: session.community.id,
          space_id: spaceData.id,
          author_id: session.currentProfile.id,
          title: title.trim(),
          content: content.trim(),
          slug,
          status: 'published'
        })

      if (postError) throw postError

      toast({
        title: "Success",
        description: "Post created successfully!"
      })

      // Reset form and close dialog
      setTitle("")
      setContent("")
      onOpenChange(false)

    } catch (error) {
      console.error('Failed to create post:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to create post. Please try again."
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[725px]">
        <DialogHeader>
          <DialogTitle>Create a new post</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Post title"
            className="text-lg font-medium"
            disabled={loading}
          />
          <RichTextEditor
            value={content}
            onChange={setContent}
            placeholder="What's on your mind?"
          />
          <div className="flex justify-end gap-2">
            <Button 
              type="button" 
              variant="outline" 
              onClick={() => onOpenChange(false)}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              {loading ? "Creating..." : "Create Post"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}