import { useEffect, useState } from 'react'
import { Check } from 'lucide-react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { getPricingFeatures } from '@/api/pricing'
import { formatBRL } from '@/lib/currency'
import type { PricingFeature } from '@/types/pricing'
import { cn } from '@/lib/utils'

const PLANS = [
  { id: 'starter', name: 'Starter', price: 49.90 },
  { id: 'influencer', name: 'Influencer', price: 129.00, popular: true },
  { id: 'professional', name: 'Professional', price: 349.00 },
  { id: 'business', name: 'Business', price: 549.00 },
  { id: 'enterprise', name: 'Enterprise', price: 10000.00 }
]

export function PricingFeatures() {
  const [features, setFeatures] = useState<PricingFeature[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getPricingFeatures().then(features => {
      setFeatures(features)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className="container mx-auto">
      <div className="space-y-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold">Compare os recursos</h2>
          <p className="text-muted-foreground">
            Veja todos os recursos disponíveis em cada plano
          </p>
        </div>

        <div className="rounded-lg border overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow className="bg-muted/50">
                <TableHead className="h-16">Funcionalidade</TableHead>
                {PLANS.map((plan) => (
                  <TableHead 
                    key={plan.id}
                    className={cn(
                      "h-16 text-center",
                      plan.popular && "relative bg-primary/5 border-x-2 border-t-2 border-primary"
                    )}
                  >
                    <div className="font-bold text-lg">{plan.name}</div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {features.map((feature) => (
                <TableRow key={feature.name}>
                  <TableCell className="font-medium">{feature.name}</TableCell>
                  <TableCell className="text-center">
                    {renderFeatureValue(feature.starter)}
                  </TableCell>
                  <TableCell className={cn(
                    "text-center",
                    "border-x-2 border-primary bg-primary/5"
                  )}>
                    {renderFeatureValue(feature.influencer)}
                  </TableCell>
                  <TableCell className="text-center">
                    {renderFeatureValue(feature.professional)}
                  </TableCell>
                  <TableCell className="text-center">
                    {renderFeatureValue(feature.business)}
                  </TableCell>
                  <TableCell className="text-center">
                    {renderFeatureValue(feature.enterprise)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell className="font-medium">Preço mensal</TableCell>
                {PLANS.map((plan) => (
                  <TableCell 
                    key={plan.id}
                    className={cn(
                      "text-center",
                      plan.popular && "border-x-2 border-b-2 border-primary bg-primary/5"
                    )}
                  >
                    <div className="space-y-4">
                      <div className="text-2xl font-bold">
                        {formatBRL(plan.price)}<span className="text-base font-normal text-muted-foreground">/mês</span>
                      </div>
                      <Button 
                        size="sm" 
                        className="w-[140px]"
                        variant={plan.popular ? "default" : "outline"}
                      >
                        Contratar
                      </Button>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

function renderFeatureValue(value: string | boolean) {
  if (typeof value === 'boolean') {
    return value ? <Check className="h-4 w-4 text-primary mx-auto" /> : null
  }
  return value
}