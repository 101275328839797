import { Skeleton } from "@/components/ui/skeleton"

export function CourseCardSkeleton() {
  return (
    <div className="rounded-lg border bg-card overflow-hidden">
      <Skeleton className="w-full aspect-video" />
      <div className="p-4 space-y-4">
        <div className="space-y-2">
          <Skeleton className="h-6 w-3/4" />
          <Skeleton className="h-4 w-full" />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1">
            <Skeleton className="h-4 w-4" />
            <Skeleton className="h-4 w-12" />
          </div>
          <Skeleton className="h-4 w-24" />
        </div>
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Skeleton className="h-8 w-8 rounded-full" />
            <div>
              <Skeleton className="h-4 w-32" />
              <Skeleton className="h-3 w-24" />
            </div>
          </div>
          <Skeleton className="h-5 w-24" />
        </div>
      </div>
    </div>
  )
}

export function FeaturedCourseCardSkeleton() {
  return (
    <div className="rounded-lg border bg-card overflow-hidden">
      <div className="grid md:grid-cols-2">
        <Skeleton className="h-64 md:h-full" />
        <div className="p-6 flex flex-col">
          <div className="flex-1 space-y-4">
            <div className="space-y-2">
              <Skeleton className="h-7 w-3/4" />
              <Skeleton className="h-4 w-full" />
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1">
                <Skeleton className="h-4 w-4" />
                <Skeleton className="h-4 w-12" />
              </div>
              <Skeleton className="h-4 w-24" />
            </div>
            <div className="flex items-center gap-2">
              <Skeleton className="h-8 w-8 rounded-full" />
              <div>
                <Skeleton className="h-4 w-32" />
                <Skeleton className="h-3 w-24" />
              </div>
            </div>
          </div>
          <Skeleton className="h-6 w-32" />
        </div>
      </div>
    </div>
  )
}

export function CoursesSkeletonList() {
  return (
    <div className="space-y-8">
      {/* Header */}
      <div>
        <Skeleton className="h-8 w-48 mb-2" />
        <Skeleton className="h-4 w-64" />
      </div>

      {/* Search */}
      <div className="relative">
        <Skeleton className="h-4 w-4 absolute left-3 top-3" />
        <Skeleton className="h-10 w-full" />
      </div>

      {/* Featured Courses */}
      <div className="space-y-4">
        <Skeleton className="h-6 w-48" />
        <div className="grid gap-6 lg:grid-cols-2">
          {Array.from({ length: 2 }).map((_, i) => (
            <FeaturedCourseCardSkeleton key={i} />
          ))}
        </div>
      </div>

      {/* Regular Courses */}
      <div className="space-y-4">
        <Skeleton className="h-6 w-32" />
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          {Array.from({ length: 8 }).map((_, i) => (
            <CourseCardSkeleton key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}