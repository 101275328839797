import { useParams } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"

interface PageBreadcrumbProps {
  items?: {
    title: string
    href?: string
  }[]
}

export function PageBreadcrumb({ items = [] }: PageBreadcrumbProps) {
  const { group_slug, space_slug } = useParams()

  // Helper to format slug for display
  const formatSlug = (slug: string) => {
    return slug?.split('-').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ')
  }

  // Build breadcrumb paths
  const paths = [
    { title: 'Dashboard', href: '/dashboard' },
    group_slug && { 
      title: formatSlug(group_slug), 
      href: `/g/${group_slug}` 
    },
    space_slug && { 
      title: formatSlug(space_slug), 
      href: `/g/${group_slug}/${space_slug}`,
      isLast: items.length === 0
    },
    ...items
  ].filter(Boolean) as Array<{
    title: string
    href?: string
    isLast?: boolean
  }>

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {paths.map((item, index) => (
          <BreadcrumbItem key={index}>
            {index > 0 && <BreadcrumbSeparator />}
            {item.href && !item.isLast ? (
              <BreadcrumbLink href={item.href}>
                {item.title}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbPage>{item.title}</BreadcrumbPage>
            )}
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  )
}