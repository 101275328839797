import { Link } from "react-router-dom"
import { Button } from "@/components/ui/button"

export function HeroSection() {
  return (
    <section className="relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-primary/5 to-primary/10 -z-10" />
      <div className="container mx-auto text-center space-y-8">
        <h1 className="text-4xl md:text-6xl font-bold max-w-4xl mx-auto leading-tight">
          O espaço onde comunidades criam impacto de verdade
        </h1>
        <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
          No Memberfy, acreditamos que comunidades vão além de números — elas são feitas de pessoas,
          de conexões reais e de ideias compartilhadas.
        </p>
        <div className="flex gap-4 justify-center">
          <Button size="lg" asChild>
            <Link to="/signup">Comece agora. É simples!</Link>
          </Button>
          <Button size="lg" variant="outline" asChild>
            <Link to="/about">Descubra como funciona</Link>
          </Button>
        </div>
      </div>
    </section>
  )
}