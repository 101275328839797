import React from 'react'
import { 
  Calendar,
  Command,
  ChevronDown,
  Users2,
  FileText,
  GraduationCap,
  Award,
  ImageIcon,
  CreditCard,
  UserPlus,
  Trophy,
  Settings,
  MessageSquare,
  Heart,
  Users,
  LayoutDashboard
} from 'lucide-react'
import { Link } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  SidebarHeader as Header,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from '@/components/ui/sidebar'
import { useSidebar } from '@/hooks/useSidebar'

const adminMenuItems = [
  {
    label: 'Comunidade',
    items: [
      { icon: Users2, label: 'Membros', href: '/admin/members' },
      { icon: Trophy, label: 'Ranking', href: '/admin/ranking' },
      { icon: UserPlus, label: 'Convidar', href: '/admin/invites' },
    ]
  },
  {
    label: 'Conteúdos',
    items: [
      { icon: MessageSquare, label: 'Publicações', href: '/admin/content' },
      { icon: LayoutDashboard, label: 'Espaços', href: '/admin/spaces' },
    ]
  },
  {
    label: 'Eventos',
    items: [
      { icon: Calendar, label: 'Eventos', href: '/admin/events' },
      { icon: FileText, label: 'Relatórios', href: '/admin/reports' },
    ]
  },
  {
    label: 'Educação',
    items: [
      { icon: GraduationCap, label: 'Cursos', href: '/admin/courses' },
      { icon: Users, label: 'Turmas', href: '/admin/classes' },
      { icon: Award, label: 'Certificados', href: '/admin/certificates' },
      { icon: FileText, label: 'Avaliações', href: '/admin/assessments' },
    ]
  },
  {
    label: 'Monetização',
    items: [
      { icon: CreditCard, label: 'Assinaturas', href: '/admin/subscriptions' },
      { icon: Users2, label: 'Afiliados', href: '/admin/affiliates' },
      { icon: FileText, label: 'Relatórios', href: '/admin/reports' },
    ]
  },
  {
    label: 'Configurações',
    items: [
      { icon: ImageIcon, label: 'Banners', href: '/admin/banners' },
      { icon: Heart, label: 'Comunidade', href: '/admin/settings/community' },
      { icon: Settings, label: 'Geral', href: '/admin/settings/general' },
    ]
  }
]

export function SidebarHeader() {
  const { isMobile } = useSidebar()

  return (
    <Header>
      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton size="lg">
                <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-primary/10">
                  <Command className="h-4 w-4" />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">Memberfy</span>
                  <span className="truncate text-xs text-muted-foreground">Workspace</span>
                </div>
                <ChevronDown className="h-4 w-4 opacity-50" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[var(--radix-dropdown-menu-trigger-width)] min-w-56 rounded-lg"
              align="start"
              side={isMobile ? "bottom" : "right"}
              sideOffset={4}
            >
              {adminMenuItems.map((group, index) => (
                <React.Fragment key={group.label}>
                  {index > 0 && <DropdownMenuSeparator />}
                  <DropdownMenuLabel>{group.label}</DropdownMenuLabel>
                  {group.items.map((item) => (
                    <DropdownMenuItem key={item.href} asChild>
                      <Link 
                        to={item.href}
                        className="flex items-center gap-2"
                      >
                        <item.icon className="h-4 w-4" />
                        <span>{item.label}</span>
                      </Link>
                    </DropdownMenuItem>
                  ))}
                </React.Fragment>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </Header>
  )
}