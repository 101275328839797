import { PaginatedResponse } from '../types'
import { eventsMockApi } from './events'
import { coursesMockApi } from './courses'
import { postsMockApi } from './posts'
import { channelsMockApi } from './channels'
import { membersMockApi } from './members'

// Helper to simulate API delay
export const delay = (ms: number = 500) => new Promise(resolve => setTimeout(resolve, ms))

// Helper to paginate results
export function paginate<T>(items: T[], page: number = 1, limit: number = 10): PaginatedResponse<T> {
  const start = (page - 1) * limit
  const end = start + limit
  const paginatedItems = items.slice(start, end)

  return {
    items: paginatedItems,
    total: items.length,
    page,
    totalPages: Math.ceil(items.length / limit),
    hasMore: end < items.length
  }
}

// Export combined mock API
export const mockApi = {
  events: eventsMockApi,
  courses: coursesMockApi,
  posts: postsMockApi,
  channels: channelsMockApi,
  members: membersMockApi
}