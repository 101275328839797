import type { Post, CreatePostParams, UpdatePostParams } from '@/types/post'
import type { SDKResponse, PaginationParams, PaginatedResponse } from '../types'
import { BaseModule } from './base'
import { mockApi } from '../mock'

export class PostsModule extends BaseModule {
  /**
   * Get all posts with pagination
   */
  async getPosts(params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Post>>> {
    if (this.config.useMocks) {
      return mockApi.posts.getPosts()
    }
    return this.get('/posts', { params })
  }

  /**
   * Get a specific post by slug
   */
  async getPostBySlug(slug: string): Promise<SDKResponse<Post>> {
    if (this.config.useMocks) {
      return mockApi.posts.getPostBySlug(slug)
    }
    return this.get(`/posts/${slug}`)
  }

  /**
   * Create a new post
   */
  async createPost(data: CreatePostParams): Promise<SDKResponse<Post>> {
    if (this.config.useMocks) {
      return mockApi.posts.createPost(data)
    }
    return this.post('/posts', data)
  }

  /**
   * Update an existing post
   */
  async updatePost(id: string, data: UpdatePostParams): Promise<SDKResponse<Post>> {
    if (this.config.useMocks) {
      return mockApi.posts.updatePost(id, data)
    }
    return this.patch(`/posts/${id}`, data)
  }

  /**
   * Delete a post
   */
  async deletePost(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/posts/${id}`)
  }

  /**
   * React to a post
   */
  async reactToPost(id: string, emoji: string): Promise<SDKResponse<Post>> {
    if (this.config.useMocks) {
      return mockApi.posts.reactToPost(id, emoji)
    }
    return this.post(`/posts/${id}/reactions`, { emoji })
  }

  /**
   * Add a comment to a post
   */
  async addComment(id: string, content: string): Promise<SDKResponse<Post>> {
    if (this.config.useMocks) {
      return mockApi.posts.addComment(id, content)
    }
    return this.post(`/posts/${id}/comments`, { content })
  }
}