import { Calendar, Video } from 'lucide-react'
import type { Event } from '@/types/event'

interface EventHeroProps {
  event: Event
  timeUntilEvent: string
}

export function EventHero({ event, timeUntilEvent }: EventHeroProps) {
  return (
    <div className="relative h-[300px] rounded-xl overflow-hidden mb-8">
      <img
        src={event.image}
        alt={event.title}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-r from-purple-500/80 to-purple-500/60" />
      <div className="absolute inset-0 flex items-center p-8">
        <div className="text-white">
          {event.series && (
            <div className="inline-block px-4 py-1 rounded-full bg-white/20 text-sm font-medium mb-4">
              {event.series}
            </div>
          )}
          <h1 className="text-4xl font-bold mb-2">{event.title}</h1>
          <div className="flex items-center gap-4 text-white/90">
            <div className="flex items-center gap-2">
              <Calendar className="h-4 w-4" />
              <span>{timeUntilEvent}</span>
            </div>
            <div className="flex items-center gap-2">
              <Video className="h-4 w-4" />
              <span>{event.type}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}