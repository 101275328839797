import { Member } from '@/types/member'
import { delay, paginate } from '.'

// Mock data
export const mockMembers: Member[] = [
  // Mock members data
]

// Mock API methods
export const membersMockApi = {
  getMembers: async () => {
    await delay()
    return {
      data: paginate(mockMembers),
      status: 200
    }
  },

  getMemberById: async (id: string) => {
    await delay()
    const member = mockMembers.find(member => member.id === id)
    return {
      data: member || null,
      status: member ? 200 : 404
    }
  }
}