import { format } from 'date-fns'
import { Calendar } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import type { Event } from '@/types/event'

interface RSVPConfirmationDialogProps {
  event: Event
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function RSVPConfirmationDialog({ event, open, onOpenChange }: RSVPConfirmationDialogProps) {
  const eventDate = new Date(event.date)

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-center">
            👋 Thank you for registering!
          </DialogTitle>
        </DialogHeader>
        <div className="text-center space-y-4">
          <p className="text-muted-foreground">
            We'll send you a reminder before the event.
          </p>
          <div className="bg-accent/50 rounded-lg p-4 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Calendar className="h-4 w-4" />
              <span>
                {format(eventDate, 'MMM d')}, {event.startTime} - {event.endTime} {event.timezone}
              </span>
            </div>
            <Button variant="outline" size="sm">
              Add to calendar
            </Button>
          </div>
          <Button className="w-full" onClick={() => onOpenChange(false)}>
            Done
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}