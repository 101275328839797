import { CheckCircle2 } from 'lucide-react'

interface CourseObjectivesProps {
  objectives: string[]
}

export function CourseObjectives({ objectives }: CourseObjectivesProps) {
  return (
    <section className="space-y-4">
      <h2 className="text-2xl font-bold">What You'll Learn</h2>
      <div className="grid md:grid-cols-2 gap-4">
        {objectives.map((objective, index) => (
          <div key={index} className="flex gap-2">
            <CheckCircle2 className="h-5 w-5 text-green-500 shrink-0" />
            <span>{objective}</span>
          </div>
        ))}
      </div>
    </section>
  )
}