import { HeroSection } from "@/components/home/HeroSection"
import { FeaturesSection } from "@/components/home/FeaturesSection"
import { WhoUsesSection } from "@/components/home/WhoUsesSection"
import { FeatureHighlightsSection } from "@/components/home/FeatureHighlightsSection"
import { PricingSection } from "@/components/home/PricingSection"
import { TestimonialsSection } from "@/components/home/TestimonialsSection"
import { CTASection } from "@/components/home/CTASection"

export function Home() {
  return (
    <div className="space-y-24 pb-24">
      <HeroSection />
      <FeaturesSection />
      <WhoUsesSection />
      <FeatureHighlightsSection />
      <PricingSection />
      <TestimonialsSection />
      <CTASection />
    </div>
  )
}