import { Separator } from '@/components/ui/separator'
import { CourseObjectives } from './CourseObjectives'
import { CourseRequirements } from './CourseRequirements'
import { CourseDescription } from './CourseDescription'
import type { Course } from '@/types/course'

interface CourseMainContentProps {
  course: Course
}

export function CourseMainContent({ course }: CourseMainContentProps) {
  return (
    <div className="space-y-8">
      <CourseObjectives objectives={course.learningObjectives} />
      <Separator />
      <CourseRequirements requirements={course.requirements} />
      <Separator />
      <CourseDescription description={course.description} />
    </div>
  )
}