import type { Member, UpdateMemberParams } from '@/types/member'
import type { SDKResponse, PaginationParams, PaginatedResponse } from '../types'
import { BaseModule } from './base'

export class MembersModule extends BaseModule {
  /**
   * Get all members with pagination
   */
  async getMembers(params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Member>>> {
    return this.get('/members', { params })
  }

  /**
   * Get a specific member by id
   */
  async getMemberById(id: string): Promise<SDKResponse<Member>> {
    return this.get(`/members/${id}`)
  }

  /**
   * Update member profile
   */
  async updateProfile(data: UpdateMemberParams): Promise<SDKResponse<Member>> {
    return this.patch('/members/profile', data)
  }

  /**
   * Follow a member
   */
  async followMember(id: string): Promise<SDKResponse<Member>> {
    return this.post(`/members/${id}/follow`)
  }

  /**
   * Unfollow a member
   */
  async unfollowMember(id: string): Promise<SDKResponse<Member>> {
    return this.post(`/members/${id}/unfollow`)
  }

  /**
   * Update member role
   */
  async updateRole(id: string, role: Member['role']): Promise<SDKResponse<Member>> {
    return this.patch(`/members/${id}/role`, { role })
  }

  /**
   * Update member status
   */
  async updateStatus(id: string, status: Member['status']): Promise<SDKResponse<Member>> {
    return this.patch(`/members/${id}/status`, { status })
  }

  /**
   * Get member stats
   */
  async getMemberStats(id: string): Promise<SDKResponse<Member['stats']>> {
    return this.get(`/members/${id}/stats`)
  }

  /**
   * Get member badges
   */
  async getMemberBadges(id: string): Promise<SDKResponse<Member['badges']>> {
    return this.get(`/members/${id}/badges`)
  }
}