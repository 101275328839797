import { createContext, useContext } from 'react'
import { useAuth } from '@/hooks/useAuth'
import type { AuthSession } from '@/types/auth'

interface AuthContextType {
  session: AuthSession | null
  loading: boolean
  signIn: (email: string, password: string, subdomain: string) => Promise<AuthSession>
  signOut: () => Promise<void>
  isAuthenticated: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth()

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }
  return context
}