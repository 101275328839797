import { useState, useEffect } from 'react'
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useCommunityTheme } from "@/providers/ThemeProvider"
import { themes } from "@/api/mock"
import { Theme } from '@/types/community'
import { ColorPicker } from '@/components/theme/ColorPicker'

export function Settings() {
  const { settings, updateTheme } = useCommunityTheme()
  const [isUploading, setIsUploading] = useState(false)
  const [selectedTheme, setSelectedTheme] = useState<Theme | null>(settings?.theme || null)
  const [customColors, setCustomColors] = useState<Record<string, string>>({})

  // Reset custom colors when theme changes
  useEffect(() => {
    if (selectedTheme) {
      setCustomColors(selectedTheme.colors)
    }
  }, [selectedTheme?.id]) // Only trigger when theme id changes

  if (!settings) return null

  const handleThemeChange = (themeId: string) => {
    const theme = themes.find(t => t.id === themeId)
    if (theme) {
      setSelectedTheme(theme)
      updateTheme({ theme })
    }
  }

  const handleColorChange = (key: string, value: string) => {
    if (selectedTheme) {
      const updatedColors = {
        ...customColors,
        [key]: value
      }
      setCustomColors(updatedColors)
      
      const updatedTheme = {
        ...selectedTheme,
        colors: updatedColors
      }
      setSelectedTheme(updatedTheme)
      updateTheme({ theme: updatedTheme })
    }
  }

  const handleLogoUpload = () => {
    setIsUploading(true)
    setTimeout(() => setIsUploading(false), 1500)
  }

  const colorGroups = [
    {
      title: "Base",
      colors: [
        { key: "background", label: "Background" },
        { key: "foreground", label: "Foreground" },
      ]
    },
    {
      title: "Primary",
      colors: [
        { key: "primary", label: "Primary" },
        { key: "primaryForeground", label: "Primary Foreground" },
      ]
    },
    {
      title: "Secondary",
      colors: [
        { key: "secondary", label: "Secondary" },
        { key: "secondaryForeground", label: "Secondary Foreground" },
      ]
    },
    {
      title: "Accent",
      colors: [
        { key: "accent", label: "Accent" },
        { key: "accentForeground", label: "Accent Foreground" },
      ]
    },
    {
      title: "Card",
      colors: [
        { key: "card", label: "Card" },
        { key: "cardForeground", label: "Card Foreground" },
      ]
    },
    {
      title: "Muted",
      colors: [
        { key: "muted", label: "Muted" },
        { key: "mutedForeground", label: "Muted Foreground" },
      ]
    },
    {
      title: "Sidebar",
      colors: [
        { key: "sidebarBackground", label: "Sidebar Background" },
        { key: "sidebarHover", label: "Sidebar Hover" },
      ]
    },
    {
      title: "Other",
      colors: [
        { key: "border", label: "Border" },
        { key: "input", label: "Input" },
        { key: "ring", label: "Ring" },
      ]
    },
  ]

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-3xl font-bold">Settings</h1>
        <p className="text-muted-foreground">
          Manage your community preferences and settings.
        </p>
      </div>
      
      <Separator />

      <div className="space-y-8">
        {/* Community Branding */}
        <div className="space-y-4">
          <div>
            <h2 className="text-lg font-semibold">Community Branding</h2>
            <p className="text-sm text-muted-foreground">
              Customize your community's visual identity
            </p>
          </div>

          <div className="grid gap-6">
            {/* Logo Upload */}
            <div className="space-y-2">
              <Label>Community Logo</Label>
              <div className="flex items-center gap-4">
                <div className="h-20 w-20 rounded-lg border bg-muted flex items-center justify-center">
                  <img
                    src={settings.logo}
                    alt="Community logo"
                    className="max-h-16 max-w-16"
                  />
                </div>
                <Button
                  variant="outline"
                  onClick={handleLogoUpload}
                  disabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Change Logo"}
                </Button>
              </div>
              <p className="text-sm text-muted-foreground">
                Recommended size: 256x256px. Max file size: 2MB.
              </p>
            </div>

            {/* Theme Selection */}
            <div className="space-y-2">
              <Label>Theme</Label>
              <Select
                value={selectedTheme?.id}
                onValueChange={handleThemeChange}
              >
                <SelectTrigger className="w-[280px]">
                  <SelectValue placeholder="Select a theme" />
                </SelectTrigger>
                <SelectContent>
                  {themes.map((theme) => (
                    <SelectItem key={theme.id} value={theme.id}>
                      <div className="flex items-center gap-2">
                        <div className="flex gap-1">
                          {theme.preview.map((color, i) => (
                            <div
                              key={i}
                              className="w-4 h-4 rounded-full"
                              style={{ backgroundColor: color }}
                            />
                          ))}
                        </div>
                        {theme.name}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <p className="text-sm text-muted-foreground">
                Choose a predefined theme or customize the colors below
              </p>
            </div>

            {/* Color Customization */}
            {selectedTheme && (
              <div className="space-y-6">
                <Separator />
                <div>
                  <h3 className="text-lg font-semibold mb-4">Theme Colors</h3>
                  <div className="grid gap-8 md:grid-cols-2">
                    {colorGroups.map((group) => (
                      <div key={group.title} className="space-y-4">
                        <h4 className="font-medium text-sm text-muted-foreground">
                          {group.title}
                        </h4>
                        <div className="space-y-4">
                          {group.colors.map(({ key, label }) => (
                            <ColorPicker
                              key={key}
                              label={label}
                              value={customColors[key] || selectedTheme.colors[key]}
                              onChange={(value) => handleColorChange(key, value)}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}