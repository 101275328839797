import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function ScrollToTop() {
  const { pathname, search, hash } = useLocation()

  useEffect(() => {
    // If there's a hash in the URL, let the browser handle the scrolling
    if (hash) return

    // Handle smooth scrolling to top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    // Also handle any scrollable containers
    const scrollableElements = document.querySelectorAll('.scroll-area')
    scrollableElements.forEach(element => {
      if (element instanceof HTMLElement) {
        element.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    })
  }, [pathname, search, hash]) // Re-run on route changes including search params

  return null
}