import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from './routes'
import { ThemeProvider } from './providers/ThemeProvider'
import { AuthProvider } from './providers/AuthProvider'
import { Toaster } from './components/ui/toaster'
import { ScrollToTop } from './components/ScrollToTop'

export default function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <BrowserRouter>
          <ScrollToTop />
          <AppRoutes />
          <Toaster />
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  )
}