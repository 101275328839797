import { useNavigate, Link, useLocation } from "react-router-dom"
import { LogOut, Settings } from "lucide-react"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"

export function SidebarFooter() {
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogout = () => {
    navigate('/login')
  }

  return (
    <div className="border-t">
      <div className="p-4 space-y-2">
        <Button
          variant="ghost"
          className={cn(
            "w-full justify-start gap-2",
            location.pathname === "/settings" && "bg-[hsl(var(--sidebarHover))]",
            "hover:bg-[hsl(var(--sidebarHover))]"
          )}
          asChild
        >
          <Link to="/settings">
            <Settings className="h-4 w-4" />
            Settings
          </Link>
        </Button>
        <Button 
          variant="ghost" 
          className="w-full justify-start gap-2 hover:bg-[hsl(var(--sidebarHover))]" 
          onClick={handleLogout}
        >
          <LogOut className="h-4 w-4" />
          Logout
        </Button>
      </div>
    </div>
  )
}