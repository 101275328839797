import { Play } from 'lucide-react'
import { Button } from '@/components/ui/button'
import type { Course } from '@/types/course'

interface CourseContentProps {
  modules: Course['modules']
  totalLectures: number
  totalHours: number
}

export function CourseContent({ modules, totalLectures, totalHours }: CourseContentProps) {
  return (
    <section className="space-y-4">
      <h2 className="text-2xl font-bold">Course Content</h2>
      <div className="text-sm text-muted-foreground">
        {modules.length} sections • {totalLectures} lectures • {totalHours} hours total
      </div>
      <div className="space-y-4">
        {modules.map((section) => (
          <div key={section.id} className="border rounded-lg">
            <div className="p-4 bg-muted/50">
              <h3 className="font-medium">{section.title}</h3>
              <p className="text-sm text-muted-foreground">
                {section.duration} minutes • {section.lectures.length} lectures
              </p>
            </div>
            <div className="divide-y">
              {section.lectures.map((lecture) => (
                <div key={lecture.id} className="p-4 flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <Play className="h-4 w-4" />
                    <div>
                      <p className="font-medium">{lecture.title}</p>
                      <p className="text-sm text-muted-foreground">
                        {lecture.duration} minutes
                      </p>
                    </div>
                  </div>
                  {lecture.preview && (
                    <Button variant="ghost" size="sm">
                      Preview
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}