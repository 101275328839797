import { Post, CreatePostParams } from '@/types/post'
import { delay, paginate } from '.'

// Helper to generate mock reactions
const generateReactions = () => {
  const emojis = ['👍', '❤️', '😂', '😮', '😢', '😡', '🎉', '👀']
  return emojis.reduce((acc, emoji) => {
    if (Math.random() > 0.5) {
      acc[emoji] = Math.floor(Math.random() * 20) + 1
    }
    return acc
  }, {} as Record<string, number>)
}

// Helper to generate mock users who liked
const generateLikedBy = (count: number) => {
  return Array.from({ length: count }, (_, i) => ({
    id: `user-${i + 1}`,
    name: `User ${i + 1}`,
    avatar: `https://api.dicebear.com/7.x/avataaars/svg?seed=User${i + 1}`
  }))
}

// Helper to generate mock comments
const generateComments = (count: number) => {
  return Array.from({ length: count }, (_, i) => ({
    id: `comment-${i + 1}`,
    user: {
      name: `Commenter ${i + 1}`,
      avatar: `https://api.dicebear.com/7.x/avataaars/svg?seed=Commenter${i + 1}`
    },
    content: `This is comment ${i + 1}. Really interesting discussion!`,
    createdAt: `${Math.floor(Math.random() * 24)}h ago`
  }))
}

// Mock data
export const mockPosts: Post[] = [
  {
    id: '1',
    slug: 'hello-from-bali',
    title: 'Hello from Bali! I am a creator in the health & wellness space',
    content: `Many of you might know me from my journey in the health and wellness sphere, where I've passionately shared tips, personal stories, and all things travel and health. I'm thrilled to join this amazing community that aligns with my shared values and aspirations. I'm so excited to venture into this new chapter with all of you, ensuring we uplift, inspire, and learn from one another. Let's thrive together!`,
    image: 'https://images.unsplash.com/photo-1707343843437-caacff5cfa74',
    createdAt: '2h ago',
    updatedAt: '2h ago',
    user: {
      id: '1',
      name: 'Lisa Jones',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Lisa',
      role: 'Health & Wellness Creator'
    },
    reactions: generateReactions(),
    likedBy: generateLikedBy(15),
    comments: generateComments(8),
    tags: ['wellness', 'introduction', 'health'],
    visibility: 'public',
    status: 'published',
    featured: true,
    group: {
      id: '1',
      name: 'Health & Wellness',
      slug: 'health-wellness'
    }
  },
  {
    id: '2',
    slug: 'mindfulness-journey',
    title: 'My Mindfulness Journey: 30 Days of Meditation',
    content: `Just completed my 30-day mindfulness challenge and I'm excited to share my experience with this amazing community. The transformation has been incredible - from better sleep to reduced anxiety. Here are my key takeaways...`,
    createdAt: '5h ago',
    updatedAt: '5h ago',
    user: {
      id: '2',
      name: 'Sarah Chen',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Sarah',
      role: 'Mindfulness Coach'
    },
    reactions: generateReactions(),
    likedBy: generateLikedBy(23),
    comments: generateComments(12),
    tags: ['mindfulness', 'meditation', 'wellness'],
    visibility: 'public',
    status: 'published',
    featured: false,
    group: {
      id: '1',
      name: 'Health & Wellness',
      slug: 'health-wellness'
    }
  }
]

// Mock databases for full lists
export const mockPostLikes = new Map(
  mockPosts.map(post => [
    post.id,
    generateLikedBy(post.likedBy.length + Math.floor(Math.random() * 50))
  ])
)

export const mockPostComments = new Map(
  mockPosts.map(post => [
    post.id,
    generateComments(post.comments.length + Math.floor(Math.random() * 20))
  ])
)

// Mock API methods
export const postsMockApi = {
  getPosts: async () => {
    await delay()
    return {
      data: {
        items: mockPosts,
        total: mockPosts.length,
        page: 1,
        totalPages: 1,
        hasMore: false
      },
      status: 200
    }
  },

  getPostBySlug: async (slug: string) => {
    await delay()
    const post = mockPosts.find(post => post.slug === slug)
    return {
      data: post || null,
      status: post ? 200 : 404
    }
  },

  createPost: async (data: CreatePostParams) => {
    await delay()
    const newPost: Post = {
      id: `post-${mockPosts.length + 1}`,
      slug: data.title.toLowerCase().replace(/\s+/g, '-'),
      createdAt: 'Just now',
      updatedAt: 'Just now',
      reactions: {},
      likedBy: [],
      comments: [],
      visibility: 'public',
      status: 'published',
      featured: false,
      ...data
    }
    mockPosts.push(newPost)
    return {
      data: newPost,
      status: 201
    }
  },

  updatePost: async (id: string, data: Partial<Post>) => {
    await delay()
    const index = mockPosts.findIndex(p => p.id === id)
    if (index === -1) {
      return {
        data: null,
        status: 404
      }
    }

    mockPosts[index] = {
      ...mockPosts[index],
      ...data,
      updatedAt: 'Just now'
    }

    return {
      data: mockPosts[index],
      status: 200
    }
  },

  reactToPost: async (postId: string, emoji: string) => {
    await delay()
    const post = mockPosts.find(p => p.id === postId)
    if (!post) {
      return {
        data: null,
        status: 404
      }
    }

    if (!post.reactions[emoji]) {
      post.reactions[emoji] = 0
    }
    post.reactions[emoji]++

    return {
      data: post,
      status: 200
    }
  },

  addComment: async (postId: string, content: string) => {
    await delay()
    const post = mockPosts.find(p => p.id === postId)
    if (!post) {
      return {
        data: null,
        status: 404
      }
    }

    const newComment = {
      id: `comment-${post.comments.length + 1}`,
      user: {
        name: 'Current User',
        avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=CurrentUser'
      },
      content,
      createdAt: 'Just now'
    }

    post.comments.push(newComment)

    return {
      data: post,
      status: 200
    }
  }
}