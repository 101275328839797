import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"

interface PricingHeaderProps {
  billingPeriod: 'monthly' | 'annual'
  onBillingPeriodChange: (period: 'monthly' | 'annual') => void
}

export function PricingHeader({ billingPeriod, onBillingPeriodChange }: PricingHeaderProps) {
  return (
    <div className="text-center space-y-8">
      <div className="space-y-4">
        <h1 className="text-4xl font-bold">
          Escolha o plano ideal para sua comunidade
        </h1>
        <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
          Conecte-se e cresça com planos flexíveis para cada estágio da sua comunidade.
        </p>
      </div>

      <div className="flex items-center justify-center gap-8">
        <div className="flex items-center gap-2">
          <Label htmlFor="billing-toggle" className="text-sm">
            Mensal
          </Label>
          <Switch
            id="billing-toggle"
            checked={billingPeriod === 'annual'}
            onCheckedChange={(checked) => 
              onBillingPeriodChange(checked ? 'annual' : 'monthly')
            }
          />
          <div className="flex items-center gap-2">
            <Label htmlFor="billing-toggle" className="text-sm">
              Anual
            </Label>
            <span className="text-xs bg-primary/10 text-primary px-2 py-0.5 rounded">
              10% de desconto
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}