import { Event } from '@/types/event'
import { delay, paginate } from '.'

// Helper to generate mock attendees
const generateAttendees = (count: number, offset: number = 0) => {
  return Array.from({ length: count }, (_, i) => ({
    id: `attendee-${offset + i + 1}`,
    name: `Attendee ${offset + i + 1}`,
    avatar: `https://api.dicebear.com/7.x/avataaars/svg?seed=Attendee${offset + i + 1}`,
    status: ['going', 'maybe', 'not-going'][Math.floor(Math.random() * 3)] as Event['attendees'][0]['status']
  }))
}

// Helper to generate mock comments
const generateComments = (count: number, offset: number = 0) => {
  return Array.from({ length: count }, (_, i) => ({
    id: `comment-${offset + i + 1}`,
    user: {
      name: `Commenter ${offset + i + 1}`,
      avatar: `https://api.dicebear.com/7.x/avataaars/svg?seed=Commenter${offset + i + 1}`
    },
    content: `This is comment ${offset + i + 1}. Really enjoying this event and learning a lot!`,
    createdAt: `${Math.floor(Math.random() * 24)}h ago`
  }))
}

// Mock data
export const mockEvents: Event[] = [
  {
    id: '1',
    slug: 'self-care-slow-soothing-practice',
    title: 'Self-Care: A Slow and Soothing Practice for Stress Relief',
    series: 'Wellness Series',
    description: `Join us for a transformative session focused on self-care and stress relief. In this guided practice, we'll explore gentle techniques that help calm the mind and soothe the body.

This session is perfect for anyone looking to:
- Develop a sustainable self-care routine
- Learn effective stress management techniques
- Create moments of peace in their daily life

No prior experience necessary. Come as you are, ready to nurture your well-being.`,
    date: '2024-06-28',
    startTime: '11:00 AM',
    endTime: '1:00 PM',
    timezone: 'PST',
    type: 'live',
    image: 'https://images.unsplash.com/photo-1545205597-3d9d02c29597',
    host: {
      name: 'Madelline Canne',
      role: 'Wellness Coach',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Madelline'
    },
    stats: {
      totalAttendees: 25,
      totalComments: 8
    },
    attendees: generateAttendees(5),
    comments: generateComments(5),
    isFeatured: true,
    saved: false,
    rsvped: false
  },
  {
    id: '2',
    slug: 'wellness-mastery-deep-breath',
    title: 'Wellness Mastery: Learning to take a deep breath',
    description: 'Join us for an immersive session on mastering the art of mindful breathing.',
    date: '2024-06-16',
    startTime: '11:00 AM',
    endTime: '1:00 PM',
    timezone: 'PST',
    type: 'live',
    image: 'https://images.unsplash.com/photo-1506126613408-eca07ce68773',
    host: {
      name: 'Madelline Canne',
      role: 'Wellness Coach',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Madelline'
    },
    stats: {
      totalAttendees: 18,
      totalComments: 6,
      totalQuestions: 3
    },
    attendees: generateAttendees(5),
    comments: generateComments(5),
    isFeatured: false,
    saved: true,
    rsvped: true,
    isLive: true,
    streamUrl: 'https://www.youtube.com/embed/PwUgQDYQ7V8',
    viewerCount: 525,
    chatMessages: [
      {
        id: '1',
        user: {
          name: 'Kelly Parks',
          avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Kelly'
        },
        content: 'This is exactly what I needed today!',
        timestamp: '9:45'
      },
      {
        id: '2',
        user: {
          name: 'Hidetoshi Nakata',
          avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Hidetoshi'
        },
        content: "I've been practicing these techniques daily and noticed a significant improvement in my stress levels.",
        timestamp: '9:51'
      }
    ],
    questions: [
      {
        id: '1',
        user: {
          name: 'Thomas Maplethorpe',
          avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Thomas'
        },
        content: 'How often should we practice these techniques?',
        timestamp: '9:54',
        answer: 'Great question! I recommend practicing at least 10 minutes daily for best results.'
      }
    ]
  },
  {
    id: '3',
    slug: 'finding-inner-peace',
    title: 'Finding Inner Peace: A Guided Meditation Session',
    description: 'Experience tranquility through guided meditation practices.',
    date: '2024-06-20',
    startTime: '11:00 AM',
    endTime: '1:00 PM',
    timezone: 'PST',
    type: 'live',
    image: 'https://images.unsplash.com/photo-1593811167562-9cef47bfc4d7',
    host: {
      name: 'Madelline Canne',
      role: 'Wellness Coach',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Madelline'
    },
    stats: {
      totalAttendees: 12,
      totalComments: 4
    },
    attendees: generateAttendees(5),
    comments: generateComments(4),
    isFeatured: false,
    saved: false,
    rsvped: false
  },
  {
    id: '4',
    slug: 'healthy-habits-workshop',
    title: 'Healthy Habits: A Wellness Workshop',
    description: 'Learn practical strategies for building lasting healthy habits.',
    date: '2024-06-28',
    startTime: '11:00 AM',
    endTime: '1:00 PM',
    timezone: 'PST',
    type: 'live',
    image: 'https://images.unsplash.com/photo-1552058544-f2b08422138a',
    host: {
      name: 'Madelline Canne',
      role: 'Wellness Coach',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Madelline'
    },
    stats: {
      totalAttendees: 15,
      totalComments: 3
    },
    attendees: generateAttendees(5),
    comments: generateComments(3),
    isFeatured: false,
    saved: false,
    rsvped: false
  }
]

// Mock databases for full lists
export const mockEventAttendees = new Map(
  mockEvents.map(event => [
    event.id,
    generateAttendees(event.stats.totalAttendees)
  ])
)

export const mockEventComments = new Map(
  mockEvents.map(event => [
    event.id,
    generateComments(event.stats.totalComments)
  ])
)

// Mock API methods
export const eventsMockApi = {
  getEvents: async () => {
    await delay()
    return {
      data: {
        items: mockEvents,
        total: mockEvents.length,
        page: 1,
        totalPages: 1,
        hasMore: false
      },
      status: 200
    }
  },

  getEventBySlug: async (slug: string) => {
    await delay()
    const event = mockEvents.find(event => event.slug === slug)
    return {
      data: event || null,
      status: event ? 200 : 404
    }
  },

  getEventAttendees: async (eventId: string, params?: { page?: number; limit?: number }) => {
    await delay()
    const attendees = mockEventAttendees.get(eventId)
    if (!attendees) {
      return {
        data: null,
        status: 404
      }
    }
    return {
      data: paginate(attendees, params?.page, params?.limit),
      status: 200
    }
  },

  getEventComments: async (eventId: string, params?: { page?: number; limit?: number }) => {
    await delay()
    const comments = mockEventComments.get(eventId)
    if (!comments) {
      return {
        data: null,
        status: 404
      }
    }
    return {
      data: paginate(comments, params?.page, params?.limit),
      status: 200
    }
  },

  rsvpEvent: async (id: string, status: Event['attendees'][0]['status']) => {
    await delay()
    const eventIndex = mockEvents.findIndex(event => event.id === id)
    if (eventIndex === -1) {
      return {
        data: null,
        status: 404
      }
    }

    const updatedEvent = {
      ...mockEvents[eventIndex],
      rsvped: status === 'going'
    }
    mockEvents[eventIndex] = updatedEvent

    return {
      data: updatedEvent,
      status: 200
    }
  },

  updateEvent: async (id: string, updates: Partial<Event>) => {
    await delay()
    const eventIndex = mockEvents.findIndex(event => event.id === id)
    if (eventIndex === -1) {
      return {
        data: null,
        status: 404
      }
    }

    const updatedEvent = {
      ...mockEvents[eventIndex],
      ...updates
    }
    mockEvents[eventIndex] = updatedEvent

    return {
      data: updatedEvent,
      status: 200
    }
  }
}