import { useState, useEffect } from 'react'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import sdk from '@/sdk'
import type { Event } from '@/types/event'

interface EventAttendeesDialogProps {
  eventId: string
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function EventAttendeesDialog({ eventId, open, onOpenChange }: EventAttendeesDialogProps) {
  const [attendees, setAttendees] = useState<Event['attendees']>([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    if (open) {
      loadAttendees()
    } else {
      // Reset state when dialog closes
      setAttendees([])
      setPage(1)
      setHasMore(false)
      setLoading(true)
    }
  }, [open, eventId])

  const loadAttendees = async (nextPage = 1) => {
    try {
      const response = await sdk.events.getEventAttendees(eventId, {
        page: nextPage,
        limit: 20
      })
      
      if (response.data) {
        setAttendees(prev => 
          nextPage === 1 ? response.data.items : [...prev, ...response.data.items]
        )
        setHasMore(response.data.hasMore)
        setPage(nextPage)
      }
    } catch (error) {
      console.error('Failed to load attendees:', error)
    } finally {
      setLoading(false)
    }
  }

  const loadMore = () => {
    if (hasMore && !loading) {
      loadAttendees(page + 1)
    }
  }

  const getStatusColor = (status: Event['attendees'][0]['status']) => {
    switch (status) {
      case 'going':
        return 'bg-green-500/10 text-green-500 hover:bg-green-500/20'
      case 'maybe':
        return 'bg-yellow-500/10 text-yellow-500 hover:bg-yellow-500/20'
      case 'not-going':
        return 'bg-red-500/10 text-red-500 hover:bg-red-500/20'
      default:
        return ''
    }
  }

  const getStatusLabel = (status: Event['attendees'][0]['status']) => {
    switch (status) {
      case 'going':
        return 'Going'
      case 'maybe':
        return 'Maybe'
      case 'not-going':
        return 'Not Going'
      default:
        return ''
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Event Attendees</DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[60vh] pr-4">
          <div className="space-y-4">
            {attendees.map((attendee) => (
              <div
                key={attendee.id}
                className="flex items-center justify-between gap-4 py-2"
              >
                <div className="flex items-center gap-3">
                  <Avatar>
                    <AvatarImage src={attendee.avatar} alt={attendee.name} />
                  </Avatar>
                  <span className="font-medium">{attendee.name}</span>
                </div>
                <Badge className={getStatusColor(attendee.status)}>
                  {getStatusLabel(attendee.status)}
                </Badge>
              </div>
            ))}

            {hasMore && (
              <div className="pt-4">
                <Button 
                  variant="outline" 
                  className="w-full" 
                  onClick={loadMore}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Load More'}
                </Button>
              </div>
            )}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}