import { format } from 'date-fns'
import { Video, Check, CalendarPlus } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import type { Event } from '@/types/event'

interface EventSidebarProps {
  event: Event
  onRSVP: () => void
  onWatchLive: () => void
}

export function EventSidebar({ event, onRSVP, onWatchLive }: EventSidebarProps) {
  const eventDate = new Date(event.date)
  const dayOfMonth = format(eventDate, 'd')

  return (
    <Card className="sticky top-8">
      <div className="p-6 space-y-6">
        {/* Date and Time */}
        <div className="flex items-start gap-4">
          <div className="bg-primary/10 rounded-lg p-2 text-center min-w-[48px]">
            <div className="text-sm font-medium uppercase">
              {format(eventDate, 'MMM')}
            </div>
            <div className="text-2xl font-bold">{dayOfMonth}</div>
          </div>
          <div>
            <p className="font-medium">
              {format(eventDate, 'EEEE, MMMM d')}
            </p>
            <p className="text-sm text-muted-foreground">
              {event.startTime} - {event.endTime} {event.timezone}
            </p>
            <div className="flex items-center gap-2 mt-2 text-sm text-muted-foreground">
              <Video className="h-4 w-4" />
              <span>{event.type}</span>
            </div>
          </div>
        </div>

        {/* RSVP or Watch Now Button */}
        {event.isLive ? (
          <Button 
            className="w-full bg-red-500 hover:bg-red-600 animate-pulse"
            onClick={onWatchLive}
          >
            Watch Now
          </Button>
        ) : event.rsvped ? (
          <div className="space-y-4">
            <div className="flex items-center gap-2 text-primary">
              <Check className="h-5 w-5" />
              <span className="font-medium">Going</span>
            </div>
            <Button className="w-full" variant="outline">
              <CalendarPlus className="mr-2 h-4 w-4" />
              Add to calendar
            </Button>
          </div>
        ) : (
          <Button className="w-full" onClick={onRSVP}>
            RSVP
          </Button>
        )}
      </div>
    </Card>
  )
}