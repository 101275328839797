import { Course } from '@/types/course'
import { FeaturedCourseCard } from './FeaturedCourseCard'

interface FeaturedCoursesProps {
  courses: Course[]
  groupSlug: string
  spaceSlug: string
}

export function FeaturedCourses({ courses, groupSlug, spaceSlug }: FeaturedCoursesProps) {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Featured Courses</h2>
      <div className="grid gap-6 lg:grid-cols-2">
        {courses.map((course) => (
          <FeaturedCourseCard
            key={course.id}
            course={course}
            groupSlug={groupSlug}
            spaceSlug={spaceSlug}
          />
        ))}
      </div>
    </div>
  )
}