interface CourseRequirementsProps {
  requirements: string[]
}

export function CourseRequirements({ requirements }: CourseRequirementsProps) {
  return (
    <section className="space-y-4">
      <h2 className="text-2xl font-bold">Requirements</h2>
      <ul className="list-disc pl-5 space-y-2">
        {requirements.map((requirement, index) => (
          <li key={index}>{requirement}</li>
        ))}
      </ul>
    </section>
  )
}