import { Course } from '@/types/course'
import { delay, paginate } from '.'

// Mock data for course enrollments
const mockEnrollments = new Map<string, number>([
  ['introduction-to-programming', 1234],
  ['advanced-react-development', 89],
  ['web-development-fundamentals', 2541],
  ['data-science-bootcamp', 24],
  ['mobile-app-development', 756],
  ['ui-ux-design', 432],
  ['cloud-computing', 891],
  ['machine-learning', 345]
])

// Mock data for course ratings
const mockRatings = new Map<string, { average: number; total: number }>([
  ['introduction-to-programming', { average: 4.8, total: 423 }],
  ['advanced-react-development', { average: 4.9, total: 156 }],
  ['web-development-fundamentals', { average: 4.7, total: 892 }],
  ['data-science-bootcamp', { average: 4.9, total: 45 }],
  ['mobile-app-development', { average: 4.6, total: 234 }],
  ['ui-ux-design', { average: 4.8, total: 167 }],
  ['cloud-computing', { average: 4.7, total: 345 }],
  ['machine-learning', { average: 4.9, total: 178 }]
])

// Mock data for course modules and lectures
const mockModules = new Map<string, Course['modules']>([
  ['introduction-to-programming', [
    {
      id: 'module-1',
      title: 'Getting Started with Programming',
      duration: 240,
      lectures: [
        {
          id: 'lecture-1-1',
          title: 'Welcome to Programming',
          duration: 15,
          preview: true,
          type: 'video'
        },
        {
          id: 'lecture-1-2',
          title: 'Setting Up Your Development Environment',
          duration: 25,
          preview: true,
          type: 'video'
        },
        {
          id: 'lecture-1-3',
          title: 'Basic Programming Concepts',
          duration: 30,
          preview: false,
          type: 'video'
        }
      ]
    },
    {
      id: 'module-2',
      title: 'Variables and Data Types',
      duration: 180,
      lectures: [
        {
          id: 'lecture-2-1',
          title: 'Understanding Variables',
          duration: 20,
          preview: false,
          type: 'video'
        },
        {
          id: 'lecture-2-2',
          title: 'Working with Data Types',
          duration: 25,
          preview: false,
          type: 'video'
        }
      ]
    }
  ]]
])

// Mock courses data
export const mockCourses: Course[] = [
  {
    id: '1',
    slug: 'introduction-to-programming',
    title: 'Introduction to Programming: From Zero to Hero',
    shortDescription: 'Start your programming journey with this comprehensive guide to coding fundamentals.',
    description: `This course is designed for absolute beginners who want to learn programming from scratch. You'll learn the core concepts of programming through hands-on exercises and real-world projects.`,
    coverImage: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6',
    previewVideo: 'https://www.youtube.com/embed/PwUgQDYQ7V8',
    type: 'ondemand',
    level: 'beginner',
    status: 'open',
    isFeatured: true,
    price: {
      isFree: false,
      current: 199.90,
      original: 399.90,
      discount: 50,
      currency: {
        code: 'BRL',
        symbol: 'R$',
        position: 'prefix'
      }
    },
    duration: {
      total: 40,
      sections: 8
    },
    enrollments: mockEnrollments.get('introduction-to-programming') || 0,
    rating: mockRatings.get('introduction-to-programming') || { average: 0, total: 0 },
    instructor: {
      id: '1',
      name: 'John Smith',
      title: 'Senior Software Engineer',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=John',
      bio: 'Experienced software engineer with a passion for teaching.'
    },
    features: [
      { icon: 'Video', title: '40 hours of video content' },
      { icon: 'FileText', title: '120 coding exercises' },
      { icon: 'Award', title: 'Certificate of completion' }
    ],
    modules: mockModules.get('introduction-to-programming') || [],
    learningObjectives: [
      'Understand fundamental programming concepts',
      'Write clean and efficient code',
      'Build real-world applications'
    ],
    requirements: [
      'No prior programming experience required',
      'Basic computer skills',
      'Willingness to learn and practice'
    ],
    visibility: 'public'
  },
  {
    id: '2',
    slug: 'advanced-react-development',
    title: 'Advanced React Development with Modern Practices',
    shortDescription: 'Master React.js with advanced patterns, performance optimization, and real-world applications.',
    description: 'Take your React skills to the next level with this comprehensive course covering advanced concepts.',
    coverImage: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee',
    type: 'ondemand',
    level: 'advanced',
    status: 'open',
    isFeatured: false,
    price: {
      isFree: false,
      current: 299.90,
      original: 499.90,
      discount: 40,
      currency: {
        code: 'BRL',
        symbol: 'R$',
        position: 'prefix'
      }
    },
    duration: {
      total: 30,
      sections: 6
    },
    enrollments: mockEnrollments.get('advanced-react-development') || 0,
    rating: mockRatings.get('advanced-react-development') || { average: 0, total: 0 },
    instructor: {
      id: '2',
      name: 'Sarah Johnson',
      title: 'React Specialist',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Sarah',
      bio: 'Frontend expert specializing in React and modern web technologies.'
    },
    features: [
      { icon: 'Video', title: '30 hours of video content' },
      { icon: 'FileText', title: '50 practical exercises' },
      { icon: 'Award', title: 'Certificate of completion' }
    ],
    modules: [],
    learningObjectives: [
      'Master advanced React patterns',
      'Optimize React applications',
      'Build complex applications'
    ],
    requirements: [
      'Solid understanding of React basics',
      'Experience with modern JavaScript',
      'Basic TypeScript knowledge'
    ],
    visibility: 'public'
  },
  {
    id: '3',
    slug: 'web-development-fundamentals',
    title: 'Web Development Fundamentals',
    shortDescription: 'Learn the essentials of web development with HTML, CSS, and JavaScript.',
    description: 'A comprehensive introduction to modern web development.',
    coverImage: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085',
    type: 'ondemand',
    level: 'beginner',
    status: 'open',
    isFeatured: true,
    price: {
      isFree: true,
      current: 0,
      original: 0,
      discount: 0,
      currency: {
        code: 'BRL',
        symbol: 'R$',
        position: 'prefix'
      }
    },
    duration: {
      total: 20,
      sections: 4
    },
    enrollments: mockEnrollments.get('web-development-fundamentals') || 0,
    rating: mockRatings.get('web-development-fundamentals') || { average: 0, total: 0 },
    instructor: {
      id: '3',
      name: 'Michael Chen',
      title: 'Web Development Instructor',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Michael',
      bio: 'Passionate about teaching web development to beginners.'
    },
    features: [
      { icon: 'Video', title: '20 hours of content' },
      { icon: 'FileText', title: '40 exercises' },
      { icon: 'Award', title: 'Certificate of completion' }
    ],
    modules: [],
    learningObjectives: [
      'Understand HTML, CSS, and JavaScript',
      'Build responsive websites',
      'Learn modern web development tools'
    ],
    requirements: [
      'No prior experience required',
      'Basic computer skills',
      'Enthusiasm to learn'
    ],
    visibility: 'public'
  },
  {
    id: '4',
    slug: 'mobile-app-development',
    title: 'Mobile App Development with React Native',
    shortDescription: 'Build cross-platform mobile apps with React Native.',
    description: 'Learn to create native mobile applications for iOS and Android.',
    coverImage: 'https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c',
    type: 'ondemand',
    level: 'intermediate',
    status: 'open',
    isFeatured: false,
    price: {
      isFree: false,
      current: 399.90,
      original: 599.90,
      discount: 33,
      currency: {
        code: 'BRL',
        symbol: 'R$',
        position: 'prefix'
      }
    },
    duration: {
      total: 35,
      sections: 7
    },
    enrollments: mockEnrollments.get('mobile-app-development') || 0,
    rating: mockRatings.get('mobile-app-development') || { average: 0, total: 0 },
    instructor: {
      id: '4',
      name: 'Emma Davis',
      title: 'Mobile Development Expert',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Emma',
      bio: 'Specialized in mobile app development and React Native.'
    },
    features: [
      { icon: 'Video', title: '35 hours of content' },
      { icon: 'FileText', title: '15 real-world projects' },
      { icon: 'Award', title: 'Certificate of completion' }
    ],
    modules: [],
    learningObjectives: [
      'Build cross-platform mobile apps',
      'Understand React Native concepts',
      'Deploy to app stores'
    ],
    requirements: [
      'Basic React knowledge',
      'JavaScript fundamentals',
      'Mobile development interest'
    ],
    visibility: 'public'
  }
]

// Mock API methods
export const coursesMockApi = {
  getCourses: async () => {
    await delay()
    return {
      data: paginate(mockCourses),
      status: 200
    }
  },

  getCourseBySlug: async (slug: string) => {
    await delay()
    const course = mockCourses.find(course => course.slug === slug)
    return {
      data: course || null,
      status: course ? 200 : 404
    }
  },

  getModules: async (courseId: string) => {
    await delay()
    const course = mockCourses.find(course => course.id === courseId)
    return {
      data: course?.modules || [],
      status: course ? 200 : 404
    }
  },

  updateProgress: async (courseId: string, lectureId: string) => {
    await delay()
    const course = mockCourses.find(course => course.id === courseId)
    if (!course) {
      return {
        data: null,
        status: 404
      }
    }

    // Simulate updating progress
    return {
      data: {
        completed: 1,
        total: course.modules.reduce((acc, module) => acc + module.lectures.length, 0),
        lastAccessed: new Date().toISOString()
      },
      status: 200
    }
  }
}