import type { Course, CreateCourseParams, UpdateCourseParams, CreateModuleParams } from '@/types/course'
import type { SDKResponse, PaginationParams, PaginatedResponse } from '../types'
import { BaseModule } from './base'
import { mockApi } from '../mock'

export class CoursesModule extends BaseModule {
  /**
   * Get all courses with pagination
   */
  async getCourses(params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Course>>> {
    if (this.config.useMocks) {
      return mockApi.courses.getCourses()
    }
    return this.get('/courses', { params })
  }

  /**
   * Get a specific course by slug
   */
  async getCourseBySlug(slug: string): Promise<SDKResponse<Course>> {
    if (this.config.useMocks) {
      return mockApi.courses.getCourseBySlug(slug)
    }
    return this.get(`/courses/${slug}`)
  }

  /**
   * Get course modules
   */
  async getModules(courseId: string): Promise<SDKResponse<Course['modules']>> {
    if (this.config.useMocks) {
      return mockApi.courses.getModules(courseId)
    }
    return this.get(`/courses/${courseId}/modules`)
  }

  /**
   * Update student progress
   */
  async updateProgress(courseId: string, lectureId: string): Promise<SDKResponse<Course['progress']>> {
    if (this.config.useMocks) {
      return mockApi.courses.updateProgress(courseId, lectureId)
    }
    return this.post(`/courses/${courseId}/progress`, { lectureId })
  }
}