import { useParams, Navigate, Routes, Route } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Posts } from '@/pages/private/Posts'
import { Events } from '@/pages/private/Events'
import { Courses } from '@/pages/private/Courses'
import { EventDetails } from '@/pages/private/EventDetails'
import { CourseDetails } from '@/pages/private/CourseDetails'
import { Channels } from '@/pages/private/Channels'
import { ChannelDetails } from '@/pages/private/ChannelDetails'
import { Members } from '@/pages/private/Members'
import { SpaceType } from '@/types/navigation'
import { fetchMenuGroups } from '@/api/menu'

export function SpaceRouter() {
  const { group_slug, space_slug } = useParams()
  const [loading, setLoading] = useState(true)
  const [group, setGroup] = useState<any>(null)
  const [space, setSpace] = useState<any>(null)

  useEffect(() => {
    const loadMenuData = async () => {
      try {
        const groups = await fetchMenuGroups()
        const foundGroup = groups.find(g => g.slug === group_slug)
        const foundSpace = foundGroup?.spaces.find(s => s.slug === space_slug)
        
        setGroup(foundGroup)
        setSpace(foundSpace)
      } catch (error) {
        console.error('Failed to load menu data:', error)
      } finally {
        setLoading(false)
      }
    }

    loadMenuData()
  }, [group_slug, space_slug])

  if (loading) {
    return <div>Loading...</div>
  }

  if (!group || !space) {
    return <Navigate to="/dashboard" replace />
  }

  // Map space_slug to the appropriate component
  const spaceComponents: Record<SpaceType, React.ComponentType> = {
    posts: Posts,
    events: Events,
    courses: Courses,
    channels: Channels,
    members: Members,
  }

  const Component = spaceComponents[space.slug as SpaceType]

  if (!Component) {
    return <Navigate to="/dashboard" replace />
  }

  return (
    <Routes>
      <Route index element={<Component />} />
      {/* Add details routes for each space type */}
      {space.slug === 'events' && (
        <Route path=":event_slug" element={<EventDetails />} />
      )}
      {space.slug === 'courses' && (
        <Route path=":course_slug" element={<CourseDetails />} />
      )}
      {space.slug === 'channels' && (
        <Route path=":channel_id" element={<ChannelDetails />} />
      )}
    </Routes>
  )
}