import { useState } from 'react'
import { PricingHeader } from '@/components/pricing/PricingHeader'
import { PricingPlans } from '@/components/pricing/PricingPlans'
import { PricingFeatures } from '@/components/pricing/PricingFeatures'
import { PricingFAQ } from '@/components/pricing/PricingFAQ'
import { CTASection } from '@/components/home/CTASection'

export function Pricing() {
  const [billingPeriod, setBillingPeriod] = useState<'monthly' | 'annual'>('monthly')

  return (
    <div className="space-y-24 pb-24">
      <PricingHeader 
        billingPeriod={billingPeriod} 
        onBillingPeriodChange={setBillingPeriod} 
      />
      <PricingPlans billingPeriod={billingPeriod} />
      <PricingFeatures />
      <PricingFAQ />
      <CTASection />
    </div>
  )
}