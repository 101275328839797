import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"
import { Zap, Palette, Users2, Star } from "lucide-react"

const features = [
  {
    icon: Zap,
    title: "Cresça no seu ritmo",
    description: "Comece pequeno e escale quando precisar."
  },
  {
    icon: Palette,
    title: "Fácil de personalizar",
    description: "Coloque sua identidade em cada detalhe."
  },
  {
    icon: Users2,
    title: "Engajamento real",
    description: "Crie espaços para conversas, cursos e eventos."
  },
  {
    icon: Star,
    title: "Sem complicações",
    description: "Integre com as ferramentas que você já ama usar."
  }
]

export function FeaturesSection() {
  return (
    <section className="container mx-auto">
      <div className="text-center space-y-3 mb-12">
        <h2 className="text-3xl font-bold">Simples, escalável e pronto para crescer com você</h2>
        <p className="text-muted-foreground max-w-2xl mx-auto">
          Você não precisa ser um expert em tecnologia para criar uma comunidade que engaja e cresce.
        </p>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12">
        {features.map((feature, i) => (
          <Card key={i} className="bg-card hover:shadow-lg transition-all">
            <CardHeader>
              <div className="h-20 w-20 rounded-2xl bg-primary/10 flex items-center justify-center mb-4 mx-auto">
                <feature.icon className="w-12 h-12 text-primary" />
              </div>
              <CardTitle className="text-xl text-center">{feature.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground text-center">{feature.description}</p>
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="mt-12 text-center">
        <Button size="lg" asChild>
          <Link to="/signup">Comece sua comunidade agora</Link>
        </Button>
      </div>
    </section>
  )
}