import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Separator } from '@/components/ui/separator'
import { SidebarHeader } from './SidebarHeader'
import { SidebarSpaces } from './SidebarSpaces'
import { SidebarFooter } from './SidebarFooter'
import { CreatePostDialog } from '@/components/posts/CreatePostDialog'
import { CreateEventDialog } from '@/components/events/CreateEventDialog'
import { CreateGroupDialog } from '@/components/groups/CreateGroupDialog'
import { AppSpace, moduleHasAction, getModuleAction } from '@/types/navigation'
import { navigationService } from '@/services/navigation'
import { useAuthContext } from '@/providers/AuthProvider'
import { useToast } from '@/components/ui/use-toast'

interface ActionDialogState {
  type: 'post' | 'event' | 'course' | null
  space: AppSpace | null
  open: boolean
}

export function Sidebar() {
  const location = useLocation()
  const { session } = useAuthContext()
  const { toast } = useToast()
  const [menuGroups, setMenuGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [openGroups, setOpenGroups] = useState<string[]>([])
  const [actionDialog, setActionDialog] = useState<ActionDialogState>({
    type: null,
    space: null,
    open: false
  })

  useEffect(() => {
    const loadMenuGroups = async () => {
      try {
        if (!session?.community.id) {
          console.log('No community ID found in session')
          return
        }

        console.log('Loading menu groups for community:', session.community.id)
        const groups = await navigationService.getMenuGroups(session.community.id)
        console.log('Loaded groups:', groups)
        
        setMenuGroups(groups)
        setOpenGroups(groups.map((group) => group.id))
      } catch (error) {
        console.error('Failed to load menu groups:', error)
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load navigation menu. Please try again later."
        })
      } finally {
        setLoading(false)
      }
    }

    loadMenuGroups()
  }, [session?.community.id])

  const toggleGroup = (groupId: string) => {
    setOpenGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId]
    )
  }

  const handleAction = (space: AppSpace) => {
    const action = getModuleAction(space.module)
    if (action) {
      setActionDialog({
        type: action,
        space,
        open: true
      })
    }
  }

  const handleCloseDialog = () => {
    setActionDialog({
      type: null,
      space: null,
      open: false
    })
  }

  if (loading) {
    return (
      <div className="w-64 h-screen bg-[hsl(var(--sidebarBackground))] border-r flex items-center justify-center">
        <div className="text-muted-foreground">Loading...</div>
      </div>
    )
  }

  const { type, space, open } = actionDialog
  const group = space ? menuGroups.find(g => g.spaces.includes(space)) : null

  return (
    <div className="w-64 h-screen bg-[hsl(var(--sidebarBackground))] border-r flex flex-col">
      <SidebarHeader />
      <Separator />
      <SidebarSpaces
        groups={menuGroups}
        openGroups={openGroups}
        onToggleGroup={toggleGroup}
        onAction={handleAction}
      />
      <SidebarFooter />

      {/* Action Dialogs */}
      {type === 'post' && space && group && (
        <CreatePostDialog
          open={open}
          onOpenChange={handleCloseDialog}
          groupSlug={group.slug}
          spaceSlug={space.slug}
        />
      )}
      {type === 'event' && space && group && (
        <CreateEventDialog
          open={open}
          onOpenChange={handleCloseDialog}
          groupSlug={group.slug}
          spaceSlug={space.slug}
        />
      )}
      <CreateGroupDialog
        open={type === 'group' && open}
        onOpenChange={handleCloseDialog}
      />
    </div>
  )
}