import { useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Users, Send } from 'lucide-react'
import { Event } from '@/api/events'

interface LiveEventDialogProps {
  event: Event
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function LiveEventDialog({ event, open, onOpenChange }: LiveEventDialogProps) {
  const [chatMessage, setChatMessage] = useState('')

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault()
    if (chatMessage.trim()) {
      // Handle sending chat message
      setChatMessage('')
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[90vw] p-0 gap-0">
        {/* Header */}
        <DialogHeader className="px-6 py-4 border-b">
          <DialogTitle className="text-xl">{event.title}</DialogTitle>
        </DialogHeader>

        {/* Main Content */}
        <div className="flex">
          {/* Video Container */}
          <div className="flex-1">
            <div className="relative" style={{ paddingTop: '56.25%' }}>
              {/* 16:9 Aspect Ratio */}
              <div className="absolute inset-0">
                <iframe
                  src={event.streamUrl}
                  title={event.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  referrerPolicy="strict-origin-when-cross-origin"
                  className="w-full h-full"
                />
                <div className="absolute bottom-4 left-4 text-white flex items-center gap-2 bg-black/50 px-3 py-1.5 rounded-full">
                  <Avatar className="h-8 w-8">
                    <AvatarImage src={event.host.avatar} alt={event.host.name} />
                  </Avatar>
                  <span className="text-sm">Apresentado por: {event.host.name}</span>
                </div>
                <div className="absolute bottom-4 right-4 text-white flex items-center gap-2 bg-black/50 px-3 py-1.5 rounded-full">
                  <Users className="h-4 w-4" />
                  <span className="text-sm">{event.viewerCount} participantes</span>
                </div>
              </div>
            </div>
          </div>

          {/* Chat Sidebar */}
          <div className="w-[400px] border-l flex flex-col bg-background">
            {/* Chat Messages */}
            <ScrollArea className="flex-1 overflow-y-auto">
              <div className="p-4 space-y-4">
                {event.chatMessages?.map((message) => (
                  <div key={message.id} className="flex gap-3">
                    <Avatar className="h-8 w-8">
                      <AvatarImage src={message.user.avatar} alt={message.user.name} />
                    </Avatar>
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium">{message.user.name}</span>
                        <span className="text-xs text-muted-foreground">{message.timestamp}</span>
                      </div>
                      <p className="text-sm">{message.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>

            {/* Chat Input */}
            <form onSubmit={handleSendMessage} className="p-4 border-t">
              <div className="flex gap-2">
                <Input
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                  placeholder="Send a message..."
                  className="flex-1"
                  aria-label="Chat message"
                />
                <Button type="submit" size="icon">
                  <Send className="h-4 w-4" />
                  <span className="sr-only">Send message</span>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
