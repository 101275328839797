import { Plan, PricingFeature } from '@/types/pricing'

const mockPlans: Plan[] = [
  {
    id: "starter",
    name: "Starter",
    description: "Ideal para criadores iniciando sua comunidade",
    price: {
      monthly: 49.90,
      annual: 539.00
    },
    limits: {
      members: "500",
      admins: "1",
      liveMembers: "50"
    },
    highlights: [
      "Perfis ricos de membros",
      "Eventos ao vivo e webinars",
      "Sem cursos e aulas",
      "Sem gamificação",
      "Sem whitelabel",
      "100GB de uploads"
    ]
  },
  {
    id: "influencer",
    name: "Influencer",
    description: "Para criadores que querem mais recursos e flexibilidade",
    popular: true,
    price: {
      monthly: 129.00,
      annual: 1399.00
    },
    limits: {
      members: "1.000",
      admins: "2",
      liveMembers: "100"
    },
    highlights: [
      "Tudo do Starter",
      "Cursos ilimitados",
      "Aulas ilimitadas",
      "200GB de uploads",
      "Branding personalizado",
      "Domínio personalizado"
    ]
  },
  {
    id: "professional",
    name: "Professional",
    description: "Recursos avançados para comunidades em crescimento",
    price: {
      monthly: 349.00,
      annual: 3769.00
    },
    limits: {
      members: "5.000",
      admins: "5",
      liveMembers: "500"
    },
    highlights: [
      "Tudo do Influencer",
      "Gamificação",
      "Notificações inteligentes",
      "Assistente de Conteúdos (ChatGPT)",
      "Suporte Prioritário",
      "Campos de perfil personalizados"
    ]
  }
]

const mockFeatures: PricingFeature[] = [
  {
    name: "Membros",
    starter: "500",
    influencer: "1.000",
    professional: "5.000",
    business: "15.000",
    enterprise: "30.000"
  },
  {
    name: "Administradores",
    starter: "1",
    influencer: "2",
    professional: "5",
    business: "10",
    enterprise: "Ilimitado"
  },
  {
    name: "Membros Simultâneos em Live",
    starter: "50",
    influencer: "100",
    professional: "500",
    business: "1.000",
    enterprise: "2.000"
  },
  {
    name: "Taxa por Venda",
    starter: "R$ 2,49",
    influencer: "R$ 2,49",
    professional: "R$ 2,49",
    business: "R$ 2,49",
    enterprise: "R$ 2,49"
  },
  {
    name: "Processamento de Pagamentos",
    starter: "6,99%",
    influencer: "6,99%",
    professional: "6,99%",
    business: "6,99%",
    enterprise: "6,99%"
  },
  {
    name: "Perfis ricos de membros",
    starter: true,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Diretório pesquisável de membros",
    starter: true,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Discussões e canais temáticos",
    starter: true,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Eventos ao vivo e webinars",
    starter: true,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Uploads ilimitados",
    starter: "100GB",
    influencer: "200GB",
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Cursos ilimitados",
    starter: false,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Aulas ilimitadas",
    starter: false,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Digest semanal da comunidade",
    starter: false,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Domínio personalizado",
    starter: false,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Branding personalizado (Whitelabel)",
    starter: false,
    influencer: true,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Gamificação",
    starter: false,
    influencer: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Notificações inteligentes",
    starter: false,
    influencer: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Assistente de Conteúdos (ChatGPT)",
    starter: false,
    influencer: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Suporte Prioritário",
    starter: false,
    influencer: false,
    professional: true,
    business: true,
    enterprise: true
  },
  {
    name: "Campos de perfil personalizados",
    starter: false,
    influencer: false,
    professional: true,
    business: true,
    enterprise: true
  }
]

// Simulate API delay
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export async function getPricingPlans(): Promise<Plan[]> {
  await delay(500)
  return mockPlans
}

export async function getPricingFeatures(): Promise<PricingFeature[]> {
  await delay(500)
  return mockFeatures
}