import { Link } from 'react-router-dom'
import { Star } from 'lucide-react'
import { Card } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { formatCurrency } from '@/lib/currency'
import type { Course } from '@/types/course'

interface FeaturedCourseCardProps {
  course: Course
  groupSlug: string
  spaceSlug: string
}

export function FeaturedCourseCard({ course, groupSlug, spaceSlug }: FeaturedCourseCardProps) {
  return (
    <Link
      to={`/g/${groupSlug}/${spaceSlug}/${course.slug}`}
      className="block"
    >
      <Card className="group overflow-hidden transition-all hover:shadow-lg">
        <div className="grid md:grid-cols-2">
          <div className="relative h-full">
            <img
              src={course.coverImage}
              alt={course.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute top-4 left-4 flex flex-wrap gap-2">
              <Badge variant={course.type === 'live' ? 'destructive' : 'secondary'}>
                {course.type === 'ondemand' ? 'On-demand' : 
                 course.type === 'live' ? 'Live' : 'Presential'}
              </Badge>
              <Badge 
                variant="secondary"
                className="bg-white/90 hover:bg-white/95"
              >
                {course.level}
              </Badge>
            </div>
            {course.status === 'limited' && (
              <Badge 
                variant="secondary"
                className="absolute bottom-4 right-4 bg-black/75 text-white hover:bg-black/85"
              >
                Limited Spots
              </Badge>
            )}
          </div>
          <div className="p-6 flex flex-col">
            <div className="flex-1">
              <h3 className="text-xl font-semibold mb-2 line-clamp-2">
                {course.title}
              </h3>
              <p className="text-muted-foreground mb-4 line-clamp-2">
                {course.shortDescription}
              </p>
              <div className="flex items-center gap-4 mb-4">
                <div className="flex items-center gap-1">
                  <Star className="h-4 w-4 fill-yellow-400 text-yellow-400" />
                  <span className="font-medium">{course.rating.average}</span>
                  <span className="text-muted-foreground">
                    ({course.rating.total})
                  </span>
                </div>
                <div className="text-sm text-muted-foreground">
                  {course.enrollments} students
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Avatar className="h-8 w-8">
                  <AvatarImage src={course.instructor.avatar} alt={course.instructor.name} />
                </Avatar>
                <div className="text-sm">
                  <div className="font-medium">{course.instructor.name}</div>
                  <div className="text-muted-foreground">{course.instructor.title}</div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              {course.price.isFree ? (
                <span className="text-lg font-semibold text-green-500">Gratuito</span>
              ) : (
                <div className="flex items-center justify-end gap-2">
                  {course.price.discount > 0 && (
                    <span className="text-sm text-muted-foreground line-through">
                      {formatCurrency(course.price.original, course.price.currency)}
                    </span>
                  )}
                  <span className="text-lg font-semibold">
                    {formatCurrency(course.price.current, course.price.currency)}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </Link>
  )
}