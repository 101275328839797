export interface AppSpace {
  id: string
  icon: string
  label: string
  slug: string
  name: string
  description: string
  module: 'Posts' | 'Events' | 'Courses' | 'Channels' | 'Members'
}

export interface AppGroup {
  id: string
  name: string
  slug: string
  spaces: AppSpace[]
}

export type SpaceType = 'posts' | 'events' | 'courses' | 'channels' | 'members'

export type IconName = 'MessageSquare' | 'Calendar' | 'MessagesSquare' | 'Users' | 'Home' | 'Settings' | 'LogOut' | 'Plus' | 'GraduationCap'

// Helper to determine if a module has actions
export const moduleHasAction = (module: AppSpace['module']): boolean => {
  return ['Posts', 'Events', 'Courses'].includes(module)
}

// Helper to get the action type based on module
export const getModuleAction = (module: AppSpace['module']): 'post' | 'event' | 'course' | null => {
  switch (module) {
    case 'Posts':
      return 'post'
    case 'Events':
      return 'event'
    case 'Courses':
      return 'course'
    default:
      return null
  }
}