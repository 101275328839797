import type { Channel, CreateChannelParams, UpdateChannelParams, SendMessageParams } from '@/types/channel'
import type { SDKResponse, PaginationParams, PaginatedResponse } from '../types'
import { BaseModule } from './base'

export class ChannelsModule extends BaseModule {
  /**
   * Get all channels with pagination
   */
  async getChannels(params?: PaginationParams): Promise<SDKResponse<PaginatedResponse<Channel>>> {
    return this.get('/channels', { params })
  }

  /**
   * Get a specific channel by slug
   */
  async getChannelBySlug(slug: string): Promise<SDKResponse<Channel>> {
    return this.get(`/channels/${slug}`)
  }

  /**
   * Create a new channel
   */
  async createChannel(data: CreateChannelParams): Promise<SDKResponse<Channel>> {
    return this.post('/channels', data)
  }

  /**
   * Update an existing channel
   */
  async updateChannel(id: string, data: UpdateChannelParams): Promise<SDKResponse<Channel>> {
    return this.patch(`/channels/${id}`, data)
  }

  /**
   * Delete a channel
   */
  async deleteChannel(id: string): Promise<SDKResponse<void>> {
    return this.delete(`/channels/${id}`)
  }

  /**
   * Send a message to a channel
   */
  async sendMessage(id: string, data: SendMessageParams): Promise<SDKResponse<Channel['messages'][0]>> {
    return this.post(`/channels/${id}/messages`, data)
  }

  /**
   * Update channel permissions
   */
  async updatePermissions(id: string, permissions: Channel['permissions']): Promise<SDKResponse<Channel>> {
    return this.patch(`/channels/${id}/permissions`, { permissions })
  }

  /**
   * Pin a message
   */
  async pinMessage(channelId: string, messageId: string): Promise<SDKResponse<Channel>> {
    return this.post(`/channels/${channelId}/pins/${messageId}`)
  }

  /**
   * React to a message
   */
  async reactToMessage(channelId: string, messageId: string, emoji: string): Promise<SDKResponse<Channel['messages'][0]>> {
    return this.post(`/channels/${channelId}/messages/${messageId}/reactions`, { emoji })
  }
}