import { CommunitySettings, Theme } from '@/types/community'

export const themes: Theme[] = [
  {
    id: 'memberfy',
    name: 'Memberfy',
    preview: ['#020817', '#0F172A', '#1E293B', '#334155', '#E2E8F0'],
    colors: {
      background: "hsl(0 0% 100%)",
      foreground: "hsl(222.2 84% 4.9%)",
      card: "hsl(0 0% 100%)",
      cardForeground: "hsl(222.2 84% 4.9%)",
      popover: "hsl(0 0% 100%)",
      popoverForeground: "hsl(222.2 84% 4.9%)",
      primary: "hsl(222.2 47.4% 11.2%)",
      primaryForeground: "hsl(210 40% 98%)",
      secondary: "hsl(210 40% 96.1%)",
      secondaryForeground: "hsl(222.2 47.4% 11.2%)",
      muted: "hsl(210 40% 96.1%)",
      mutedForeground: "hsl(215.4 16.3% 46.9%)",
      accent: "hsl(210 40% 96.1%)",
      accentForeground: "hsl(222.2 47.4% 11.2%)",
      destructive: "hsl(0 84.2% 60.2%)",
      destructiveForeground: "hsl(210 40% 98%)",
      border: "hsl(214.3 31.8% 91.4%)",
      input: "hsl(214.3 31.8% 91.4%)",
      ring: "hsl(222.2 84% 4.9%)",
      sidebarBackground: "hsl(0 0% 100%)",
      sidebarHover: "hsl(210 40% 93%)"
    }
  },
  {
    id: 'forest-mist',
    name: 'Forest Mist',
    preview: ['#1a2f1c', '#2c4a2e', '#3e653f', '#507f51', '#c8e6c9'],
    colors: {
      background: "hsl(120 10% 100%)",
      foreground: "hsl(120 20% 4.9%)",
      card: "hsl(120 10% 100%)",
      cardForeground: "hsl(120 20% 4.9%)",
      popover: "hsl(120 10% 100%)",
      popoverForeground: "hsl(120 20% 4.9%)",
      primary: "hsl(120 20% 15%)",
      primaryForeground: "hsl(120 10% 98%)",
      secondary: "hsl(120 30% 96.1%)",
      secondaryForeground: "hsl(120 20% 11.2%)",
      muted: "hsl(120 30% 96.1%)",
      mutedForeground: "hsl(120 16.3% 46.9%)",
      accent: "hsl(120 30% 96.1%)",
      accentForeground: "hsl(120 20% 11.2%)",
      destructive: "hsl(0 84.2% 60.2%)",
      destructiveForeground: "hsl(0 0% 98%)",
      border: "hsl(120 31.8% 91.4%)",
      input: "hsl(120 31.8% 91.4%)",
      ring: "hsl(120 20% 4.9%)",
      sidebarBackground: "hsl(120 30% 98%)",
      sidebarHover: "hsl(120 30% 93%)"
    }
  },
  {
    id: 'desert-sand',
    name: 'Desert Sand',
    preview: ['#8B4513', '#A0522D', '#CD853F', '#DEB887', '#F5DEB3'],
    colors: {
      background: "hsl(34 10% 100%)",
      foreground: "hsl(34 20% 4.9%)",
      card: "hsl(34 10% 100%)",
      cardForeground: "hsl(34 20% 4.9%)",
      popover: "hsl(34 10% 100%)",
      popoverForeground: "hsl(34 20% 4.9%)",
      primary: "hsl(34 20% 15%)",
      primaryForeground: "hsl(34 10% 98%)",
      secondary: "hsl(34 30% 96.1%)",
      secondaryForeground: "hsl(34 20% 11.2%)",
      muted: "hsl(34 30% 96.1%)",
      mutedForeground: "hsl(34 16.3% 46.9%)",
      accent: "hsl(34 30% 96.1%)",
      accentForeground: "hsl(34 20% 11.2%)",
      destructive: "hsl(0 84.2% 60.2%)",
      destructiveForeground: "hsl(0 0% 98%)",
      border: "hsl(34 31.8% 91.4%)",
      input: "hsl(34 31.8% 91.4%)",
      ring: "hsl(34 20% 4.9%)",
      sidebarBackground: "hsl(34 30% 98%)",
      sidebarHover: "hsl(34 30% 93%)"
    }
  },
  {
    id: 'midnight-gold',
    name: 'Midnight Gold',
    preview: ['#000033', '#000066', '#0000CC', '#FFD700', '#FFF68F'],
    colors: {
      background: "hsl(240 100% 10%)",
      foreground: "hsl(51 100% 97%)",
      card: "hsl(240 100% 13%)",
      cardForeground: "hsl(51 100% 97%)",
      popover: "hsl(240 100% 13%)",
      popoverForeground: "hsl(51 100% 97%)",
      primary: "hsl(51 100% 50%)",
      primaryForeground: "hsl(240 100% 10%)",
      secondary: "hsl(240 60% 30%)",
      secondaryForeground: "hsl(51 100% 97%)",
      muted: "hsl(240 60% 20%)",
      mutedForeground: "hsl(51 30% 70%)",
      accent: "hsl(51 100% 50%)",
      accentForeground: "hsl(240 100% 10%)",
      destructive: "hsl(0 84.2% 60.2%)",
      destructiveForeground: "hsl(0 0% 98%)",
      border: "hsl(240 60% 25%)",
      input: "hsl(240 60% 25%)",
      ring: "hsl(51 100% 50%)",
      sidebarBackground: "hsl(240 100% 15%)",
      sidebarHover: "hsl(240 100% 20%)"
    }
  },
  {
    id: 'ocean-breeze',
    name: 'Ocean Breeze',
    preview: ['#001F3F', '#083358', '#0F5E9C', '#2D8FD5', '#74D1F7'],
    colors: {
      background: "hsl(210 10% 100%)",
      foreground: "hsl(210 20% 4.9%)",
      card: "hsl(210 10% 100%)",
      cardForeground: "hsl(210 20% 4.9%)",
      popover: "hsl(210 10% 100%)",
      popoverForeground: "hsl(210 20% 4.9%)",
      primary: "hsl(210 90% 30%)",
      primaryForeground: "hsl(210 10% 98%)",
      secondary: "hsl(210 30% 96.1%)",
      secondaryForeground: "hsl(210 20% 11.2%)",
      muted: "hsl(210 30% 96.1%)",
      mutedForeground: "hsl(210 16.3% 46.9%)",
      accent: "hsl(210 30% 96.1%)",
      accentForeground: "hsl(210 20% 11.2%)",
      destructive: "hsl(0 84.2% 60.2%)",
      destructiveForeground: "hsl(0 0% 98%)",
      border: "hsl(210 31.8% 91.4%)",
      input: "hsl(210 31.8% 91.4%)",
      ring: "hsl(210 20% 4.9%)",
      sidebarBackground: "hsl(210 50% 98%)",
      sidebarHover: "hsl(210 50% 93%)"
    }
  }
]

export async function fetchCommunitySettings(): Promise<CommunitySettings> {
  const storedSettings = localStorage.getItem('communitySettings')
  if (storedSettings) {
    return JSON.parse(storedSettings)
  }

  const defaultSettings = {
    name: "Memberfy",
    logo: "/memberfy-logo.svg",
    theme: themes[0],
  }

  localStorage.setItem('communitySettings', JSON.stringify(defaultSettings))
  return defaultSettings
}

export async function updateCommunitySettings(settings: Partial<CommunitySettings>): Promise<CommunitySettings> {
  const currentSettings = await fetchCommunitySettings()
  const updatedSettings = {
    ...currentSettings,
    ...settings
  }

  localStorage.setItem('communitySettings', JSON.stringify(updatedSettings))
  return updatedSettings
}