export function formatCurrency(amount: number, currency: { code: string; symbol: string; position: 'prefix' | 'suffix' }) {
  const formatter = new Intl.NumberFormat(currency.code === 'BRL' ? 'pt-BR' : 'en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const formattedAmount = formatter.format(amount)
  return currency.position === 'prefix' 
    ? `${currency.symbol} ${formattedAmount}`
    : `${formattedAmount} ${currency.symbol}`
}

export function formatBRL(value: number): string {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}