import type { AppSpace } from '@/types/navigation'

export interface PostBannerProps {
  space?: AppSpace; // Adicione a propriedade `space`
  onCreatePost?: () => void
}

export function PostBanner({ space, onCreatePost }: PostBannerProps) {
  return (
    <div className="relative h-[160px] rounded-xl overflow-hidden">
      <img
        src="https://images.unsplash.com/photo-1707343843437-caacff5cfa74"
        alt="Community Banner"
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-r from-purple-500/80 to-purple-500/60 flex items-center p-8">
        <div className="text-white">
          {/* <div className="inline-block px-4 py-1 rounded-full bg-white/20 text-sm font-medium mb-4">
            Welcome
          </div> */}
          <h1 className="text-4xl font-bold mb-2">{space?.name}</h1>
          <p className="text-lg text-white/90">{space?.description}</p>
        </div>
      </div>
    </div>
  )
}