import { Play, FileText, Award } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { formatCurrency } from '@/lib/currency'
import { cn } from '@/lib/utils'
import type { Course } from '@/types/course'

interface CoursePurchaseProps {
  course: Course
  className?: string
}

export function CoursePurchase({ course, className }: CoursePurchaseProps) {
  return (
    <div className={cn("rounded-lg border bg-card overflow-hidden", className)}>
      {course.previewVideo ? (
        <div className="relative pb-[56.25%]">
          <iframe
            src={course.previewVideo}
            title="Course Preview"
            className="absolute inset-0 w-full h-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ) : (
        <img
          src={course.coverImage}
          alt={course.title}
          className="w-full aspect-video object-cover"
        />
      )}
      <div className="p-6 space-y-6">
        <div className="space-y-2">
          {course.price.isFree ? (
            <div className="text-3xl font-bold text-green-500">Gratuito</div>
          ) : (
            <>
              <div className="text-3xl font-bold">
                {formatCurrency(course.price.current, course.price.currency)}
              </div>
              {course.price.discount > 0 && (
                <div className="flex items-center gap-2">
                  <span className="text-lg text-muted-foreground line-through">
                    {formatCurrency(course.price.original, course.price.currency)}
                  </span>
                  <span className="text-sm bg-primary/10 text-primary px-2 py-0.5 rounded">
                    {course.price.discount}% off
                  </span>
                </div>
              )}
            </>
          )}
        </div>

        <div className="space-y-4">
          <Button className="w-full" size="lg">
            {course.price.isFree ? 'Enroll Now' : 'Buy Now'}
          </Button>
          {course.type === 'ondemand' && (
            <Button variant="outline" className="w-full">
              Try 10 minutes for free
            </Button>
          )}
        </div>

        <div className="space-y-4 pt-4 border-t">
          <h4 className="font-medium">This course includes:</h4>
          <ul className="space-y-3">
            {course.features.map((feature, index) => (
              <li key={index} className="flex items-center gap-2 text-sm">
                {feature.icon === 'Video' && <Play className="h-4 w-4" />}
                {feature.icon === 'FileText' && <FileText className="h-4 w-4" />}
                {feature.icon === 'Award' && <Award className="h-4 w-4" />}
                <span>{feature.title}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}